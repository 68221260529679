import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-dripshipper',
  templateUrl: './add-dripshipper.component.html',
  styleUrls: ['./add-dripshipper.component.css']
})
export class AddDripshipperComponent implements OnInit {

  value=0
  formatos=["json", "csv","xml"]
  dropshipper:any = {delimitador:';'}

  constructor(private api:ApiService, public dialogRef: MatDialogRef<AddDripshipperComponent>,private route:Router) { }

  ngOnInit(): void {
  }

  changeTheValue(event:any){
    this.value = event
    this.dropshipper['margen'] = event
  }

  dynamicChange(field:string, event:any){
    this.dropshipper[field] = event.target.value
  }

  confirmar(){
    this.api.postDropshippers(this.dropshipper).subscribe((resp:any)=>{
      this.route.navigate(['seccion/dropshipper/' + resp.id + '/detalles'])
      this.dialogRef.close()
    })
  }

}
