<div class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input type="text" class="form-control" (ngModel)="producto.name" name="name" id="exampleInputEmail1"
      aria-describedby="emailHelp" value="{{producto.name}}" (change)="changeNombre($event)">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputPassword1" class="form-label">Categoria</label>
    <select class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon"
      (ngModel)="producto.categoria" [value]="producto.categoria" name="categoria" (change)="changeCategoria($event)">
      <option *ngFor="let cat of categorias" value="{{cat.id}}">{{cat.nombre}}</option>
    </select>
  </div>
  <div class="mb-3 col-12">
    <label for="exampleInputPassword1" class="form-label">Descripcion</label>
    <textarea class="form-control" id="exampleInputPassword1" (ngModel)="producto.descripcion" rows="5"
      name="descripcion" (change)="changeDescripcion($event)">{{producto.descripcion}}</textarea>
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Ean</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.ean" value="{{producto.ean}}"
      name="ean" (change)="changeEan($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Sku</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.sku" value="{{producto.sku}}"
      name="sku" (change)="changeSku($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Referencia</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.referencia"
      value="{{producto.referencia}}" name="referencia" (change)="changeReferencia($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Stock</label>
    <input type="number" class="form-control" id="exampleInputPassword1" (ngModel)="producto.stock"
      value="{{producto.stock}}" name="stock" (change)="changeStock($event)">
    <div id="emailHelp" class="form-text">A partir de este se calcular el stock virtual ({{producto.virtual}})</div>
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Precio Compra</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.precio_compra"
      value="{{producto.precio_compra}}" name="precio_compra" (input)="setPrecioCompra($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Canon</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.canon"
      value="{{producto.canon}}" name="canon" (change)="addCanon($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Coste Extra</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.coste_extra"
      value="{{producto.coste_extra}}" name="coste_extra" (change)="addExtraCost($event)">
  </div>

  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Descuento Precio</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.descuento"
      value="{{producto.descuento}}" name="descuento" (change)="addDescuento($event)">
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Margen</label>
    <input type="number" class="form-control" min="0" max="3" step="0.10" (ngModel)="producto.margen"
      (input)="newMargen($event)" value="{{producto.margen}}" name="margen" />
  </div>
  <div class="mb-3 col-2">
    <label for="exampleInputPassword1" class="form-label">Impuestos</label>
    <select class="form-select" id="inputGroupSelect04" (ngModel)="producto.impuesto" (change)="changeImpuestos($event)"
      aria-label="Example select with button addon">
      <option selected value="0.21">21%</option>
      <option value="0.15">15%</option>
      <option value="0.10">10%</option>
      <option value="0.05">5%</option>
    </select>
  </div>
  <div class="mb-3 col-2">
    <label for="exampleInputPassword1" class="form-label">Importe subtotal</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.importe" name="importe"
      value="{{producto.importe}}" readonly>
  </div>
  <div class="mb-3 col-2">
    <label for="exampleInputPassword1" class="form-label">Importe impuestos</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.impuestos" name="impuestos"
      value="{{producto.impuestos}}" readonly>
  </div>
  <div class="mb-3 col-3">
    <label for="exampleInputPassword1" class="form-label">Precio Venta</label>
    <input type="text" class="form-control" id="exampleInputPassword1" (ngModel)="producto.price" name="price"
      value="{{producto.price}}" readonly>
  </div>
  <button type="button" class="btn btn-primary w-100" (click)="crear()">Crear</button>
</div>
