import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { CambiarEstadoPedidoComponent } from '../cambiar-estado-pedido/cambiar-estado-pedido.component';

@Component({
  selector: 'app-delete-producto',
  templateUrl: './delete-producto.component.html',
  styleUrls: ['./delete-producto.component.css']
})
export class DeleteProductoComponent implements OnInit {

  producto:any;

  constructor(private api:ApiService,public dialogRef: MatDialogRef<DeleteProductoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.producto = data;
   }

  ngOnInit(): void {
  }

  cerrar(){
    this.dialogRef.close();
  }

  eliminar(){
    this.api.deleteProductos(this.producto).subscribe((resp:any)=>{
      this.cerrar();
    })
  }

}
