import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-info-mensaje',
  templateUrl: './info-mensaje.component.html',
  styleUrls: ['./info-mensaje.component.css']
})
export class InfoMensajeComponent implements OnInit {

  mensaje:string=''

  constructor(public snackBarRef: MatSnackBarRef<InfoMensajeComponent>,@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.mensaje = data
  }

  ngOnInit(): void {
  }

  cerrar(){
    this.snackBarRef.dismiss()
  }

}
