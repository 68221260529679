import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-del-producto-proveedor',
  templateUrl: './del-producto-proveedor.component.html',
  styleUrls: ['./del-producto-proveedor.component.css']
})
export class DelProductoProveedorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
