<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Hora Inicio Jornada Mañana</label>
    <input name="descuento" type="time" (change)="changeField('hora_inicio_morning',$event)" [value]="fichaje.hora_inicio_morning" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Hora Fin Jornada Mañana</label>
    <input name="descuento" type="time" (change)="changeField('hora_fin_morning',$event)" [value]="fichaje.hora_fin_morning" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Hora Inicio Jornada Tarde</label>
    <input name="descuento" type="time" (change)="changeField('hora_inicio_tarde',$event)" [value]="fichaje.hora_inicio_tarde" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Hora Fin Jornada Tarde</label>
    <input name="descuento" type="time" (change)="changeField('hora_fin_tarde',$event)" [value]="fichaje.hora_fin_tarde"  class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <button type="submit" class="btn btn-primary btn-sm">Fichar</button>
</form>
