import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-crear-producto',
  templateUrl: './crear-producto.component.html',
  styleUrls: ['./crear-producto.component.css']
})
export class CrearProductoComponent implements OnInit {

  producto:any = {precio_compra:0,margen:0.3,canon:0,coste_extra:0,descuento:0};
  categorias:any[]=[];
  selectedFiles:any = {}
  archivoSubir: any = null
  impuesto:any

  constructor(private api:ApiService,public dialogRef: MatDialogRef<CrearProductoComponent>) { }

  ngOnInit(): void {
      this.api.getCategoriasAll().subscribe((resp:any)=>{
        this.categorias = resp;
      });
  }

  crear(){
    if(this.producto.virtual>0){
      this.producto.activo = true
    }
    delete this.producto.image
    this.api.postProductos(this.producto).subscribe(()=>{
        this.dialogRef.close()
    })
  }

  setPrecioCompra(ev:any){
    this.producto.precio_compra = ev.target.value;
  }

  addCanon(ev:any){
    this.producto.canon = ev.target.value;
    let parcial = parseFloat(this.producto.precio_compra) + parseFloat(this.producto.precio_compra)*parseFloat(this.producto.margen) + parseFloat(this.producto.canon) + parseFloat(this.producto.coste_extra) - parseFloat(this.producto.descuento);
    this.producto.importe = parcial.toFixed(2);
  }

  addExtraCost(ev:any){
    this.producto.coste_extra = ev.target.value;
    let parcial = parseFloat(this.producto.precio_compra) + parseFloat(this.producto.precio_compra)*parseFloat(this.producto.margen) + parseFloat(this.producto.canon) + parseFloat(this.producto.coste_extra) - parseFloat(this.producto.descuento);
    this.producto.importe = parcial.toFixed(2);
  }

  addDescuento(ev:any){
    this.producto.descuento = ev.target.value;
    let parcial = parseFloat(this.producto.precio_compra) + parseFloat(this.producto.precio_compra)*parseFloat(this.producto.margen) + parseFloat(this.producto.canon) + parseFloat(this.producto.coste_extra) - parseFloat(this.producto.descuento);
    this.producto.importe = parcial.toFixed(2);
  }

  newMargen(ev:any){
    if(this.impuesto==null){
      this.impuesto = 0.21
    }
    this.producto.margen = ev.target.value;
    let parcial = parseFloat(this.producto.precio_compra) + parseFloat(this.producto.precio_compra)*parseFloat(this.producto.margen) + parseFloat(this.producto.canon) + parseFloat(this.producto.coste_extra) - parseFloat(this.producto.descuento);
    this.producto.impuestos = (parseFloat(this.producto.importe)*this.impuesto).toFixed(2);
    this.producto.price = parseFloat(this.producto.impuestos) + parseFloat(this.producto.importe);
    this.producto.importe = parcial.toFixed(2);
    this.producto.price = parseFloat(this.producto.price).toFixed(2)

  }

  changeStock(ev:any){
    this.producto.stock = ev.target.value;
    this.producto.virtual = Math.floor(this.producto.stock*0.7)
  }

  changeReferencia(ev:any){
    this.producto.referencia = ev.target.value;
  }

  changeSku(ev:any){
    this.producto.sku = ev.target.value;
  }

  changeEan(ev:any){
    this.producto.ean = ev.target.value;
  }

  changeDescripcion(ev:any){
    this.producto.descripcion = ev.target.value;
  }

  changeNombre(ev:any){
    this.producto.name = ev.target.value;
  }

  changeImpuestos(ev:any){
    this.impuesto = parseFloat(ev.target.value);
    this.producto.impuestos = (parseFloat(this.producto.importe)*this.impuesto).toFixed(2);
    this.producto.price = parseFloat(this.producto.impuestos) + parseFloat(this.producto.importe);
    this.producto.price = parseFloat(this.producto.price).toFixed(2)
  }

  changeCategoria(ev:any){
    this.producto.categoria = ev.target.value;
  }

}
