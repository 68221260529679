<form (ngSubmit)="agregar()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Host</label>
    <input type="text" class="form-control" [(ngModel)]="servidor.host" name="host"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Puerto</label>
    <input type="number" class="form-control" [(ngModel)]="servidor.puerto" name="puerto"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Usuario</label>
    <input type="text" class="form-control" [(ngModel)]="servidor.usuario" name="usuario"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Contraseña</label>
    <input type="password" class="form-control" [(ngModel)]="servidor.password" name="password"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 form-check">
    <label for="exampleInputEmail1" class="form-check-label">Usa TLS</label>
    <input type="checkbox" class="form-check-input" [(ngModel)]="servidor.usa_tls" name="usa_tls"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 form-check">
    <label for="exampleInputEmail1" class="form-check-label">Usa SSL</label>
    <input type="checkbox" class="form-check-input" [(ngModel)]="servidor.usa_ssl" name="usa_ssl"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <button type="submit" class="btn btn-primary">{{texto}}</button>
</form>
