import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-categoria-padre',
  templateUrl: './add-categoria-padre.component.html',
  styleUrls: ['./add-categoria-padre.component.css']
})
export class AddCategoriaPadreComponent implements OnInit {

  categoria:any={}
  referencia: string = "";
  categoriaSel:any={};
  resultados:any[]=[];
  searhCategorias = new Subject<string>();
  

  constructor(private api:ApiService,public dialogRef: MatDialogRef<AddCategoriaPadreComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoria = data
    this.searhCategorias.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        //console.log(value);
        if(value.length > 1){
          this.api.searchGestionCategorias(this.referencia).subscribe((data:any)=>{
            this.resultados = data.results;
          });
        }
      });
   }

  ngOnInit(): void {
  }

  selecionar(categoria:any){
    this.categoriaSel = categoria;
    this.resultados = [];
  }

  agregar(){
    this.categoria.padre = this.categoriaSel.id
    this.api.putGestionCategorias(this.categoria).subscribe((data:any)=>{
      this.dialogRef.close();
    });
  }

}
