import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { PedidoAddProductoComponent } from '../pedido-add-producto/pedido-add-producto.component';

@Component({
  selector: 'app-mod-producto-proveedor',
  templateUrl: './mod-producto-proveedor.component.html',
  styleUrls: ['./mod-producto-proveedor.component.css']
})
export class ModProductoProveedorComponent implements OnInit {

  linea: any = {};
  pedido: any = {};
  unidades: number = 0;
  descuento = 0.0;
  maxDescuento=0.0;
  producto: any = {}

  constructor(private api: ApiService, public dialogRef: MatDialogRef<ModProductoProveedorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.linea = data.linea
    this.pedido = data.pedido
  }

  ngOnInit(): void {
    this.api.getProductos(this.linea.producto.id).subscribe((pro:any)=>{
      this.producto = pro
    })
  }

  actualizar() {
    this.linea.producto = this.producto.id
    this.api.putGestionPedidosProveedorProductos(this.pedido,this.linea).subscribe((data:any)=>{
      this.api.totalizarGestionPedidosProveedorProductosRecepcionarUnidad(this.pedido.id, data.id).subscribe((data: any) => {
        this.dialogRef.close();
      })
    })
  }

  checkMaximun(ev:any){
    if(ev>this.maxDescuento){
      this.linea.descuentoLinea = this.maxDescuento
    }
  }

  recallDiscount(ev:any){
    this.linea.unidades = ev
    this.maxDescuento = ((this.producto.precio_compra*this.producto.margen)/2)*this.linea.unidades
  }

}
