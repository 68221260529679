import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Carrito } from 'src/app/models/carrito';
import { AddDescuentoComponent } from '../add-descuento/add-descuento.component';
import { ApiService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-fichaje',
  templateUrl: './fichaje.component.html',
  styleUrls: ['./fichaje.component.css']
})
export class FichajeComponent implements OnInit {

  fichaje:any = {}
  nodata:boolean = false
  fechaActual:string|null = "";
  user:any

  constructor(public dialogRef: MatDialogRef < FichajeComponent >, private api: ApiService,public datepipe: DatePipe , @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit(): void {
    this.fechaActual = this.datepipe.transform((new Date), 'yyyy-MM-dd');
    this.api.getCurrentUser().subscribe((user:any)=>{
      this.user = user
      this.api.getCurrentFichaje().subscribe((data:any)=>{
        if(data==null){
          this.nodata= true
        }else{
          this.fichaje = data
        }
      });
    })
    
  }

  changeField(field:string, ev:any){
    this.fichaje[field] = ev.target.value
  }

  agregar() {
    console.log(this.fichaje)
    if(this.nodata==true){
      this.fichaje['usuario'] = this.user.id 
      this.fichaje['fecha'] = this.fechaActual
      this.api.postFichajes(this.fichaje).subscribe((resp:any)=>{
        this.dialogRef.close()
      })
    }else{
      this.api.putFichajes(this.fichaje).subscribe((resp:any)=>{
        this.dialogRef.close()
      })

    }
    //this.dialogRef.close()
  }


}
