<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="oferta.nombre"  name="nombre" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Codigo</label>
    <input [(ngModel)]="oferta.codigo" name="codigo" type="text" maxlength="6" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Fecha Inicio</label>
    <input [(ngModel)]="oferta.inicio" name="fechaInicio" type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Fecha Fin</label>
    <input [(ngModel)]="oferta.fin"  name="fechaFin" type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Importe para aplicar descuento</label>
    <input [(ngModel)]="oferta.maximoParaAplicar"  name="maximoParaAplicar" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Descuento(Importe)</label>
    <input [(ngModel)]="oferta.importeDescuento"  name="importeDescuento" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Descuento(Porcentual)</label>
    <input [(ngModel)]="oferta.descuento"  name="descuento" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Porcentual</label><br>
    <input [(ngModel)]="oferta.porcentual" type="checkbox" name="porcentual" class="check-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Activo</label><br>
    <input [(ngModel)]="oferta.activo" type="checkbox" name="porcentual" class="check-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" class="btn btn-primary btn-sm">Agregar</button>
</form>

