<form (ngSubmit)="actualizar()">
  <div class="mb-3">
    <div class="list-group">
      <a href="#" class="list-group-item list-group-item-action" aria-current="true">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{linea.producto.name}}</h5>
          <small>{{linea.producto.referencia}}</small>
        </div>
        <p class="mb-1">{{linea.producto.price}} €</p>
        <div class="fw-bold">Virtual/Stock: {{linea.producto.virtual}}/{{linea.producto.stock}}</div>
      </a>
    </div>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Unidades</label>
    <input [(ngModel)]="linea.unidades" [max]="linea.producto.virtual" name="unidades" type="number" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp" (ngModelChange)="recallDiscount($event)">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Descuento</label>
    <input [(ngModel)]="linea.descuentoLinea" name="descuentoLinea" type="number" step="0.1"
      [max]="maxDescuento" class="form-control" id="exampleInputEmail1" (ngModelChange)="checkMaximun($event)">
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" [disabled]="linea.unidades <= 0" class="btn btn-primary btn-sm w-100">Modificar</button>
</form>
