import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mod-sliders',
  templateUrl: './mod-sliders.component.html',
  styleUrls: ['./mod-sliders.component.css']
})
export class ModSlidersComponent implements OnInit {

  slider: any = {}
  formData = new FormData()
  selectedFiles: any;

  constructor(private api:ApiService,public dialogRef: MatDialogRef<ModSlidersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.slider = data
   }

  ngOnInit(): void {
  }

  agregar() {
    this.formData.append('id', this.slider.id);
    this.formData.append('titulo', this.slider.titulo);
    this.formData.append('subtitulo', this.slider.subtitulo);
    if(this.slider.url==null){
      this.formData.append('url', this.slider.url);
    }
    this.formData.append('activo', this.slider.activo);

    this.api.putSliders(this.slider.id,this.formData).subscribe((data: any) => {
      this.dialogRef.close()
    })
  }

  selectImage(event: any): void {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      let file: File | null = this.selectedFiles.item(0);
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let data = this.formData.get('imagen')
        if (data == null) {
          this.formData.append('imagen', file);
        } else {
          this.formData.delete('imagen')
          this.formData.append('imagen', file);
        }
      }
    }
  }

}
