import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pedido-mensajes',
  templateUrl: './pedido-mensajes.component.html',
  styleUrls: ['./pedido-mensajes.component.css']
})
export class PedidoMensajesComponent implements OnInit {

  pedido:any
  accion:number=0

  constructor(private api: ApiService, public dialogRef: MatDialogRef<PedidoMensajesComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    if(data){
      this.pedido = data.pedido
      this.accion = data.accion
    }
  }

  ngOnInit(): void {
  }

  accionPedido(){
    if(this.accion==0){
      this.api.PedidoGestionConfirmar(this.pedido.id).subscribe((resp:any)=>{
        this.dialogRef.close()
      })
    }else{
      this.api.PedidoGestionAnular(this.pedido.id).subscribe((resp:any)=>{
        this.dialogRef.close()
      })
    }
  }

}
