import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { GrupoEmpresarialModalComponent } from '../grupo-empresarial-modal/grupo-empresarial-modal.component';
import { FormControl } from '@angular/forms';
import { Observable, startWith, map, forkJoin } from 'rxjs';

@Component({
  selector: 'app-descuento-por-volumen',
  templateUrl: './descuento-por-volumen.component.html',
  styleUrls: ['./descuento-por-volumen.component.css']
})
export class DescuentoPorVolumenComponent implements OnInit {

  descuento: any = { pais: 'ES', min: 0, max: 0, descuento: 0.1, producto: null }
  editar: boolean = false
  texto: string = "Guardar"
  paises: any[] = []
  productos: any = { results: [] }
  myControl = new FormControl('');

  constructor(private api: ApiService, public dialogRef: MatDialogRef<GrupoEmpresarialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.descuento = data
      this.texto = "Actualizar"
      this.editar = true
    }

  }

  loadData() {
    forkJoin([this.api.getPaisesBasic(), this.api.getProductos(null)]).subscribe((result: any[]) => {
      this.paises = result[0]
      this.productos = result[1]
    })
  }

  searchResult(ev: any) {
    let resultado = ev.target.value
    if (resultado.lenght > 3) {
      this.api.searchProductos(resultado).subscribe((resp: any) => {
        this.productos = resp
      })
    } else {
      this.api.getProductos(null).subscribe((resp: any) => {
        this.productos = resp
      })
    }
  }

  selectProducto(ev:any){
    if(ev.option.value=="null"){
      this.descuento.producto = null
    }else{
      this.descuento.producto = ev.option.value
    }
    
  }

  ngOnInit(): void {
    this.loadData()
  }

  guardarResultados() {
    if (this.editar == true) {
      this.api.putDescuentoVolumen(this.descuento).subscribe((resp: any) => {
        this.dialogRef.close()
      })
    } else {
      this.api.postDescuentoVolumen(this.descuento).subscribe((resp: any) => {
        this.dialogRef.close()
      })
    }
  }

  onchangeData(field: string, ev: any) {
    if(ev.target.value!=""){
      this.descuento[field] = ev.target.value
    }else{
      this.descuento[field] = null
    }
  }

  getPercentDiscount(data: any) {
    return `${data.descuento * 100} %`
  }

  isSelected(data: any) {
    if (data.pais == this.descuento.pais) {
      return true
    } else {
      return false
    }
  }

}
