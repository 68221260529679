import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-notificar-pedido',
  templateUrl: './notificar-pedido.component.html',
  styleUrls: ['./notificar-pedido.component.css']
})
export class NotificarPedidoComponent implements OnInit {

  pedido:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<NotificarPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.pedido=data
  }

  ngOnInit(): void {
  }

  notificarPedido(){
    this.api.getNotificarPedido(this.pedido.id).subscribe((noti:any)=>{
      console.log(noti)
      this.dialogRef.close()
    });
  }

}
