import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css']
})
export class SearchSelectComponent implements OnInit {
  

  _items: any[] = [];

    @Input() placeholder: string = '';
    @Input() showSearch = true;
    @Input() showStatus = true;
    @Input() showError = false;
    @Output() itemChange = new EventEmitter();

    @Input('items')
    set items(items: any[]) {
        this._items = items;
        this._items.map(item => {
            item.visible = item.visible || true;
        });
        this.filtered = [...this._items];
    }

    filtered: any[] = [];
    item: any = null;

    private searchText = '';

    get search(): string {
        return this.searchText;
    }

    set search(searchText: string) {
        this.searchText = searchText;

        const search = this.searchText.toLowerCase();
        if (!search) {
            this.filtered = [...this._items];
            return;
        }
        this.filtered = this._items.filter(i => i.nombre.toLowerCase().indexOf(search) !== -1);
    }

    get isEmpty(): boolean {
        return this.filtered.filter(i => i.visible).length === 0;
    }

    ngOnInit() {
    
    }

    trackById(item: any): number {
        return item.id;
    }

    onChange(item: any): void {
        this.item = item;
        this.itemChange.emit(item);
    }
  }