import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Carrito } from 'src/app/models/carrito';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-descuento-global',
  templateUrl: './add-descuento-global.component.html',
  styleUrls: ['./add-descuento-global.component.css']
})
export class AddDescuentoGlobalComponent implements OnInit {

  pedido: any = {}
  descuento:number = 0
  descuentoMaximo:number = 0

  constructor(private api:ApiService, public dialogRef: MatDialogRef < AddDescuentoGlobalComponent > , @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
    this.descuento = this.pedido.descuentoGlobal
  }

  ngOnInit(): void {
    this.calcImporte()
  }

  async calcImporte(){
    this.pedido.pproductos.forEach((linea:any) => {
     this.api.getProductos(linea.producto.id).subscribe((resp:any)=>{
      this.descuentoMaximo = this.descuentoMaximo + parseFloat(((parseFloat(resp.margen)*parseFloat(resp.precio_compra))*linea.unidades).toFixed(2))
      })
    });
  }

  agregar() {
    if(this.descuento<=this.descuentoMaximo){
      this.pedido.descuentoGlobal = this.descuento
    }else{
      this.pedido.descuentoGlobal = this.descuentoMaximo
    }
    this.api.putPedidos(this.pedido).subscribe((data:any)=>{
      this.dialogRef.close()
    })
  }


}
