<div class="row">
  <div class="mb-3">
    <div class="list-group">
      <div class="list-group-item list-group-item-action" *ngFor="let linea of productos;"
        [ngClass]="{'border border-4 border-success': linea.recepcionado==true,'border border-4 border-warning': linea.selecionada==true,'bg-white': linea.selecionada==false}"
        aria-current="true">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{linea.descripcion}}</h5>
          <small>{{linea.referencia}}</small>
          <p>Unidades: {{linea.unidades}}</p>
        </div>
        <div class="d-flex w-100 justify-content-between mb-2">
          <label for="exampleFormControlInput1" class="form-label">Unidades a Recepcionar</label>
          <input class="form-control form-control-sm" max="{{linea.unidades}}" type="number" value="{{linea.unidadesRecepcionadas}}"
            [disabled]="linea.unidadesRecepcionadas==linea.unidades" (change)="changeUnids(linea,'unidadesRecepcionadas',$event)">
        </div>
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1">Precio Compra: {{linea.precio_compra}} €</p>
          <button class="btn btn-sm btn-info" (click)="selecionar(linea)">Selecionar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-group w-100" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-danger btn-sm" (click)="recepcionarPedido()">Recepcion Completa Pedido</button>
    <button type="button" class="btn btn-info btn-sm" (click)="recepcionarUnidad()">Recepcion Completa Selecionados</button>
    <button type="button" class="btn btn-warning btn-sm" (click)="recepcionParcial()">Recepcion Parcial Selecionados</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="cerrar()">Cerrar</button>
  </div>
</div>
