<form (ngSubmit)="guardarDatosPedido()">
  <div class="mb-3">
    <!-- (ngModelChange)="recallDiscount($event)" -->
    <label for="exampleInputEmail1" class="form-label">Asignar Metodo de Transporte</label>
    <select class="form-select" aria-label="Default select example"
      (change)="cambiarCarrier($event)">
      <option *ngFor="let met of metodos" value="{{met.id}}" [selected]="met.id===pedido.carrier">{{met.nombre}}</option>
    </select>
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Cambiar Metodo y recalcular envio</button>
</form>
