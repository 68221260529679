<form (ngSubmit)="agregar()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Forma de Pago</label>
    <select class="form-select" aria-label="Default select example" [(ngModel)]="formaPago.nombre" name="nombre">
      <option *ngFor="let fPago of formasPagoBase" [value]="fPago.code">{{fPago.value}}</option>
    </select>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Texto Publico</label>
    <input type="text" class="form-control" [(ngModel)]="formaPago.texto_mostrar" name="texto_mostrar" />
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Comision Variable</label>
    <input type="text" class="form-control" [(ngModel)]="formaPago.comision_variable" name="comision_variable" />
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Importe Fijo</label>
    <input type="text" class="form-control" [(ngModel)]="formaPago.valor_fijo" name="valor_fijo" />
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" [(ngModel)]="formaPago.tpv" name="tpv">
    <label class="form-check-label" for="flexCheckChecked">
      TPV
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" [(ngModel)]="formaPago.ecommerce" name="ecommerce">
    <label class="form-check-label" for="flexCheckChecked">
      Ecommerce
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" [(ngModel)]="formaPago.movil" name="movil">
    <label class="form-check-label" for="flexCheckChecked">
      App Movil
    </label>
  </div>
  <button type="submit" class="btn btn-primary">{{texto}}</button>
</form>
