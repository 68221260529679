import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-configurar-correo',
  templateUrl: './configurar-correo.component.html',
  styleUrls: ['./configurar-correo.component.css']
})
export class ConfigurarCorreoComponent implements OnInit {

  servidor: any = {};
  actualizar: boolean = false;
  texto:string = "Crear";

  constructor(private api: ApiService, public dialogRef: MatDialogRef<ConfigurarCorreoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.servidor = data;
      this.actualizar = true;
      this.texto = "Actualizar";
    }
  }

  ngOnInit(): void {
  }

  agregar() {
    if (this.actualizar == false) {
      this.api.postServidorCorreo(this.servidor).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    } else {
      this.api.putServidorCorreo(this.servidor).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    }
  }

}
