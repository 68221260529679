import { Component, Input, OnInit } from '@angular/core';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-label-status',
  templateUrl: './label-status.component.html',
  styleUrls: ['./label-status.component.css']
})
export class LabelStatusComponent implements OnInit {

  @Input()
  estado:Boolean = false

  @Input()
  textoOk:string = "On"

  @Input()
  textoKo:string = "Off"

  faOK = faCheck
  faKO = faXmark

  constructor() { }

  ngOnInit(): void {
  }

}
