<div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Eliminar el Producto:</label>
    <div class="col-sm-10">
      <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="{{producto.name}}">
    </div>
  </div>
  <div class="btn-group" role="group" aria-label="Basic example">
  <button type="button" class="btn btn-danger" (click)="eliminar()">Confirmar</button>
  <button type="button" class="btn btn-info" (click)="cerrar()">Cerrar</button>
  </div>
</div>

