import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-carrier-rates-configuracion',
  templateUrl: './carrier-rates-configuracion.component.html',
  styleUrls: ['./carrier-rates-configuracion.component.css']
})
export class CarrierRatesConfiguracionComponent implements OnInit {

  rate:any = {importe_minimo:0.0, importe_maximo:0,tarifa_iva:0}
  paises: any

  constructor(private api:ApiService,public dialogRef: MatDialogRef<CarrierRatesConfiguracionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.rate = data
   }

  ngOnInit(): void {
    this.api.getPaisesBasic().subscribe((resp:any)=>{
      this.paises = resp
    })
  }

  
  onChangeInput(ev:any, campo:any){
    this.rate[campo] = ev.target.value
  }

  agregarImporte(){
    this.api.putCarrierRates(this.rate.carrier, this.rate).subscribe((resp:any)=>{
      this.rate = {importe_minimo:0.0, importe_maximo:0.0,tarifa_iva:0.0}
      this.dialogRef.close()
    })
  }

}
