import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mod-categoria',
  templateUrl: './mod-categoria.component.html',
  styleUrls: ['./mod-categoria.component.css']
})
export class ModCategoriaComponent implements OnInit {

  categoria:any={}
  valor_comision_comercial = 0

  constructor(private api:ApiService,public dialogRef: MatDialogRef<ModCategoriaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoria = data
    this.valor_comision_comercial = this.categoria.comision_comercial
   }

  ngOnInit(): void {
  }

  actualizar(){
    this.api.putGestionCategorias(this.categoria).subscribe(()=>{
      this.dialogRef.close()
    })
  }

  // buscarPadre(ev:any){
  //   this.api.searchGestionCategorias(texto)
  // }

  changeTheValue(event:any){
    this.valor_comision_comercial = event
    this.categoria['comision_comercial'] = event
  }

}
