import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {



  apiUrl = environment.URL_API;

  constructor(private http: HttpClient) { }

  /* METHODS  */
  getCurrentUser(): any {
    return this.http.get(this.apiUrl + 'usuario/currentUser/');
  }

  getTipoEmpresas(): any {
    return this.http.get(this.apiUrl + 'tipo-empresas/');
  }

  searchUser(texto: string, rol: number): any {
    return this.http.get(this.apiUrl + 'usuario/?search=' + texto + "&rol=" + rol);
  }

  notificarCreacioUsuario(user_id: number): any {
    return this.http.get(this.apiUrl + 'usuario/' + user_id + "/notificar_creacion/");
  }

  postUsuario(user: any): any {
    return this.http.post(this.apiUrl + 'usuario/', user);
  }

  putUsuario(user: any): any {
    return this.http.put(this.apiUrl + 'usuario/' + user.id + "/", user);
  }

  putParcialUsuario(user: any): any {
    return this.http.patch(this.apiUrl + 'usuario/' + user.id + "/", user);
  }

  deleteUsuario(user: any): any {
    return this.http.delete(this.apiUrl + 'usuario/' + user.id + "/");
  }

  getUsuarioDirecciones(id: number): any {
    return this.http.get(this.apiUrl + 'usuario/' + id + '/direcciones/');
  }

  getUsuarioPedidos(id: number): any {
    return this.http.get(this.apiUrl + 'usuario/' + id + '/pedidos/');
  }

  postUsuarioDirecciones(id: number, direccion: any): any {
    return this.http.post(this.apiUrl + 'usuario/' + id + '/direcciones/', direccion);
  }

  putUsuarioDirecciones(id: number, direccion: any): any {
    return this.http.put(this.apiUrl + 'usuario/' + id + '/direcciones/' + direccion.id + '/', direccion);
  }

  postUsuarioCarrier(user_id: any, data: any): any {
    return this.http.post(this.apiUrl + 'usuario/' + user_id + '/addTransportista/', data);
  }

  deleteUsuarioCarrier(user_id: any, data: any): any {
    return this.http.post(this.apiUrl + 'usuario/' + user_id + '/deleteTransportista/', data);
  }

  getPaisesBasic(): any {
    return this.http.get(this.apiUrl + '/paises/')
  }

  getRegionesSearch(country: string | null): any {
    return this.http.get(this.apiUrl + '/paises/' + country + '/capitales/')
  }

  getCuidadesSearch(id_region: number | null): any {
    return this.http.get(this.apiUrl + '/paises/' + id_region + '/ciudades/')
  }

  getUsuario(id: number): any {
    return this.http.get(this.apiUrl + 'usuario/' + id + '/');
  }

  getUsuarioByRol(rol_id: number): any {
    return this.http.get(this.apiUrl + 'usuario/?rol=' + rol_id);
  }

  getGestionUsuario(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-usuarios/' + id + '/');
  }

  postGestionUsuario(usuario: any): any {
    return this.http.post(this.apiUrl + 'gestion-usuarios/', usuario);
  }

  putGestionUsuario(usuario: any): any {
    return this.http.put(this.apiUrl + 'gestion-usuarios/' + usuario.id + '/', usuario);
  }

  getUsuarioClientes(): any {
    return this.http.get(this.apiUrl + 'gestion-usuarios/clientes/');
  }

  getUsuarioAdmins(): any {
    return this.http.get(this.apiUrl + 'gestion-usuarios/admins/');
  }

  getUsuarioColaboradores(): any {
    return this.http.get(this.apiUrl + 'gestion-usuarios/colaboradores/');
  }

  getUsuarioDistribuidoras(): any {
    return this.http.get(this.apiUrl + 'gestion-usuarios/clientes/');
  }

  getUsuarioRoles(): any {
    return this.http.get(this.apiUrl + 'usuario/roles/');
  }

  getUsuarioComprasMensuales(id_user: any): any {
    return this.http.get(this.apiUrl + 'usuario/' + id_user + '/ordenes_mensuales_usuario/');
  }

  getUsuarioComprasAnuales(id_user: any): any {
    return this.http.get(this.apiUrl + 'usuario/' + id_user + '/ordenes_anuales_usuario/');
  }

  getVendedorVentasMensuales(id_user: any): any {
    return this.http.get(this.apiUrl + 'usuario/' + id_user + '/ordenes_anuales_vendedor/');
  }

  getVendedorVentasAnuales(id_user: any): any {
    return this.http.get(this.apiUrl + 'usuario/' + id_user + '/ordenes_mensuales_vendedor/');
  }

  descontarUnidadesPedidos(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/descontar_unidades/');
  }

  eliminarPedidoGestion(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/eliminar/');
  }

  PedidoGestionConfirmar(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/confirmar_pedido/');
  }

  PedidoGestionAnular(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/anular_pedido/');
  }

  getPedidos(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'gestion-pedidos/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/');
    }
  }

  calcularComisionPedidoByID(id_pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id_pedido + '/calcular_comision/');
  }

  calcularImportePedido(id_pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id_pedido + '/calcular_importe/');
  }

  totalizarPedido(id_pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id_pedido + '/totalizar/');
  }


  getNotificarPedido(id: number | null): any {
    return this.http.get(this.apiUrl + 'pedidos/' + id + '/notificar/');
  }

  clonarPedido(id: number | null) {
    return this.http.get(this.apiUrl + 'pedidos/' + id + '/clonar/');
  }

  postNotificarPresupuesto(id: number | null, data: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/' + id + '/notificar_presupuesto/', data);
  }

  putPedidos(pedido: any | null): any {
    return this.http.put(this.apiUrl + 'pedidos/' + pedido.id + '/', pedido);
  }

  getPedidoCrearUrlPago(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + id + '/crear_url_pago/');
  }

  postPedidos(pedido: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/', pedido);
  }

  postPedidoPructosBulk(id: number, pedido: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/' + id + "/save_productos_pedido/", pedido);
  }

  putPedidoProductosFromPedido(id: number, produto: any): any {
    return this.http.put(this.apiUrl + 'gestion-pedidos/' + id + "/productos-pedidos/", produto);
  }

  putPedidoProductos(produto: any): any {
    return this.http.put(this.apiUrl + 'productos-pedidos/' + produto.id + '/', produto);
  }

  calcularImporteTransporte(pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + pedido.id + '/calcular_importe/');
  }

  searchPedidos(ref: string | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/?search=' + ref);
  }
  searchPedidosComerciales(ref: string | null, vendedor: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/?search=' + ref + "&vendedor=" + vendedor);
  }

  postPedidosProductosComercial(id: string | null, data: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/' + id + '/productos-pedidos/', data);
  }

  deletePedidosProductosComercial(id: string | null, id_prod_ped: string | null): any {
    return this.http.delete(this.apiUrl + 'gestion-pedidos/' + id + '/productos-pedidos/' + id_prod_ped + '/');
  }

  confirmarDisponibilidadesPedido(lineas: any[]): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/confirmar_disponibilidades/', lineas);
  }


  getPedidosComerciales(id: string | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'pedidos-comerciales/');
    } else {
      return this.http.get(this.apiUrl + 'pedidos-comerciales/' + id + '/');
    }
  }

  calcularDatosFiscalesPedido(pedido: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/devolver_datos_generales/', pedido);
  }

  pedidoCrearUrlPago(pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/crear_url_pago/');
  }

  postPedidosComerciales(pedido: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos/', pedido);
  }

  putPedidosComerciales(pedido: any): any {
    return this.http.put(this.apiUrl + 'gestion-pedidos/', pedido);
  }

  deletePedidosComerciales(pedido: any): any {
    return this.http.delete(this.apiUrl + 'gestion-pedidos/' + pedido.id + '/');
  }

  downloadGestionPedidos(pedido: any): any {
    //return this.http.get(this.apiUrl + 'gestion-pedidos/' + pedido.id + '/descargar_pdf/');
    return environment.URL_API + 'gestion-pedidos/' + pedido.id + '/descargar_pdf/'
  }

  generarNumeracionPedidos(pedido: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos/' + pedido.id + "/gen_numeracion/");
  }

  tipoPedidoAdmins(): any {
    return this.http.get(this.apiUrl + 'tipo-pedido-admins/');
  }

  tipoPedidoFreelances(): any {
    return this.http.get(this.apiUrl + 'tipo-pedido-freelances/');
  }

  getEspresas(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'empresas/');
    } else {
      return this.http.get(this.apiUrl + 'empresas/' + id + '/');
    }
  }

  postEspresas(data: any): any {
    return this.http.post(this.apiUrl + 'empresas/', data);
  }

  putEspresas(data: any): any {
    return this.http.put(this.apiUrl + 'empresas/' + data.id + "/", data);
  }

  estadosPedido(): any {
    return this.http.get(this.apiUrl + 'estado-pedidos');
  }

  getEspresaCurrent(): any {
    return this.http.get(this.apiUrl + 'empresas/current/');
  }

  getCategorias(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'categorias/');
    } else {
      return this.http.get(this.apiUrl + 'categorias/' + id + '/');
    }
  }

  getCategoriasAll(): any {
    return this.http.get(this.apiUrl + 'categorias/all/');
  }

  searchCategorias(busqueda: string | null): any {
    return this.http.get(this.apiUrl + 'categorias/?search=' + busqueda);
  }

  getGestionCategorias(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'gestion-categorias/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-categorias/' + id + '/');
    }
  }

  getGestionCategoriasPrincipales(): any {
    return this.http.get(this.apiUrl + 'gestion-categorias/principales/');
  }

  getGestionCategoriasTrending(): any {
    return this.http.get(this.apiUrl + 'gestion-categorias/trending/');
  }

  getGestionCategoriasAll(): any {
    return this.http.get(this.apiUrl + 'gestion-categorias/all/');
  }

  searchGestionCategorias(texto: string): any {
    return this.http.get(this.apiUrl + 'gestion-categorias/?search=' + texto);
  }

  postGestionCategorias(cate: any): any {
    return this.http.post(this.apiUrl + 'gestion-categorias/', cate);
  }


  putGestionCategorias(cate: any): any {
    return this.http.put(this.apiUrl + 'gestion-categorias/' + cate.id + "/", cate);
  }


  deleteGestionCategorias(cate: any): any {
    return this.http.delete(this.apiUrl + 'gestion-categorias/' + cate.id + "/");
  }


  getProductos(id: any | null): any {
    if (id != null) {
      return this.http.get(this.apiUrl + 'gestion-productos/' + id + '/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-productos/');
    }
  }

  getProductosAll(): any {
    return this.http.get(this.apiUrl + 'gestion-productos/all/');
  }

  postProductos(data: any): any {
    return this.http.post(this.apiUrl + 'gestion-productos/', data);
  }

  putProductos(data: any): any {
    return this.http.put(this.apiUrl + 'gestion-productos/' + data.id + "/", data);
  }

  patchProductos(data: any): any {
    return this.http.patch(this.apiUrl + 'gestion-productos/' + data.id + "/", data);
  }

  deleteProductos(data: any): any {
    return this.http.delete(this.apiUrl + 'gestion-productos/' + data.id + "/");
  }

  generateBarcodeProductos(data: any): any {
    return this.http.get(this.apiUrl + 'gestion-productos/' + data.id + "/generar_barcode/");
  }

  getProductoDisponible(id: any | null): any {
    return this.http.get(this.apiUrl + 'gestion-productos/' + id + '/disponible/');
  }

  condicionesProductos(): any {
    return this.http.get(this.apiUrl + 'gestion-productos/condiciones/');
  }

  generateAllBarcodeProductos(): any {
    return this.http.get(this.apiUrl + 'productos/genarar_codigos_barra/');
  }

  imprimirEtiquetaProducto(producto_id: number, dataPrint: any): any {
    return this.http.post(this.apiUrl + 'gestion-productos/' + producto_id + "/imprimir_etiquetas/", dataPrint)
  }

  imprimirEtiquetaProductoGet(producto_id: number, numero: any): any {
    return this.apiUrl + 'gestion-productos/' + producto_id + "/imprimir_etiquetas_get/?numero=" + numero
  }

  searchProductos(busqueda: string | null): any {
    return this.http.get(this.apiUrl + 'gestion-productos/?search=' + busqueda);
  }

  searchCliente(busqueda: string | null): any {
    return this.http.get(this.apiUrl + 'usuario/?search=' + busqueda);
  }

  getFormasPago(): any {
    return this.http.get(this.apiUrl + 'formas-pago/');
  }

  getFormasPagoTPV(): any {
    return this.http.get(this.apiUrl + 'formas-pago/tpv/');
  }

  calcularComisionPedido(datos: any): any {
    return this.http.post(this.apiUrl + 'gestion-formas-pago/calcular_comision/', datos);
  }

  getCanal(): any {
    return this.http.get(this.apiUrl + 'canal-pedidos/');
  }

  getSliders(): any {
    return this.http.get(this.apiUrl + 'sliders/');
  }

  postSliders(slider: any): any {
    return this.http.post(this.apiUrl + 'sliders/', slider);
  }

  putSliders(id: number, slider: any): any {
    return this.http.put(this.apiUrl + 'sliders/' + id + '/', slider);
  }

  deleteSliders(id: number): any {
    return this.http.delete(this.apiUrl + 'sliders/' + id + '/');
  }

  getConceptosRma(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'conceptosrmas/');
    } else {
      return this.http.get(this.apiUrl + 'conceptosrmas/' + id + '/');
    }
  }

  postConceptosRma(data: any): any {
    return this.http.post(this.apiUrl + 'conceptosrmas/', data);
  }

  putConceptosRma(data: any): any {
    return this.http.put(this.apiUrl + 'conceptosrmas/' + data.id + '/', data);
  }

  deleteConceptosRma(id: any): any {
    return this.http.delete(this.apiUrl + 'conceptosrmas/' + id + '/');
  }

  getRmas(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'rmas/');
    } else {
      return this.http.get(this.apiUrl + 'rmas/' + id + '/');
    }
  }

  goToUrl(urlDestino: string): any {
    return this.http.get(urlDestino);
  }


  getDropshippers(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'dropshippers/');
    } else {
      return this.http.get(this.apiUrl + 'dropshippers/' + id + '/');
    }
  }

  postDropshippers(drop: any): any {
    return this.http.post(this.apiUrl + 'dropshippers/', drop);
  }

  putDropshippers(drop: any): any {
    return this.http.put(this.apiUrl + 'dropshippers/' + drop.id + '/', drop);
  }

  deleteDropshippers(id: number | null): any {
    return this.http.delete(this.apiUrl + 'dropshippers/' + id + '/');
  }

  getCupones(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'gestion-cupones/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-cupones/' + id + '/');
    }
  }

  postCupones(data: any): any {
    return this.http.post(this.apiUrl + 'gestion-cupones/', data);
  }

  putCupones(data: any): any {
    return this.http.put(this.apiUrl + 'gestion-cupones/' + data.id + "/", data);
  }

  deleteCupones(data: any): any {
    return this.http.delete(this.apiUrl + 'gestion-cupones/' + data.id + "/");
  }

  doLogin(data: any): any {
    return this.http.post(this.apiUrl + 'login/', data);
  }

  getEstadisticasPedidosMensuales(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_mensuales/');
  }

  getEstadisticasPedidosAnuales(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_anuales/');
  }

  getEstadisticasPedidosNumeroAnuales(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_anuales_numero/');
  }

  getEstadisticasPedidosNumeroMensuales(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_mensuales_numero/');
  }

  getEstadisticasPedidosBeneficioAnual(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_mensuales_numero/');
  }

  getEstadisticasPedidosBeneficioMensual(): any {
    return this.http.get(this.apiUrl + 'estadisticas/ordenes_mensuales_numero/');
  }

  getGeneralData(): any {
    return this.http.get(this.apiUrl + 'estadisticas/general/');
  }

  getCashFlow(): any {
    return this.http.get(this.apiUrl + 'estadisticas/cash_flow/');
  }

  getProveedor(id: any): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'proveedores/');
    } else {
      return this.http.get(this.apiUrl + 'proveedores/' + id + '/');
    }
  }

  getProveedoresAll(): any {
    return this.http.get(this.apiUrl + 'proveedores/all/');
  }

  searchProveedor(referencia: any): any {
    return this.http.get(this.apiUrl + 'proveedores/?search=' + referencia);
  }

  postProveedor(data: any): any {
    return this.http.post(this.apiUrl + 'proveedores/', data);
  }

  putProveedor(data: any): any {
    return this.http.put(this.apiUrl + 'proveedores/' + data.id + '/', data);
  }

  deleteProveedor(id: number | null): any {
    return this.http.delete(this.apiUrl + 'proveedores/' + id + '/');
  }

  getProductosProveedor(id: any) {
    return this.http.get(this.apiUrl + 'proveedores/' + id + '/productos/');
  }

  searchProductosProveedor(id: any, prodID: string) {
    return this.http.get(this.apiUrl + 'proveedores/' + id + '/productos/?search=');
  }

  getPedidosProveedor(id: any) {
    return this.http.get(this.apiUrl + 'proveedores/' + id + '/pedidos/');
  }

  putParcialProducto(prod: any): any {
    return this.http.patch(this.apiUrl + 'gestion-productos/' + prod.id + "/", prod);
  }

  getContactos(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'contactos/');
    } else {
      return this.http.get(this.apiUrl + 'contactos/' + id + '/');
    }
  }

  putContactos(contacto: any): any {
    return this.http.put(this.apiUrl + 'contactos/' + contacto.id + '/', contacto);
  }

  postContactosRespueta(id: any, respueta: any): any {
    return this.http.post(this.apiUrl + 'contactos/' + id + '/responder/', respueta);
  }

  deleteContactos(id: number | null): any {
    return this.http.delete(this.apiUrl + 'contactos/' + id + '/');
  }

  contactoToSpam(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'proveedores/');
    } else {
      return this.http.get(this.apiUrl + 'proveedores/' + id + '/');
    }
  }

  getServidorCorreo(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'servidores-correo/');
    } else {
      return this.http.get(this.apiUrl + 'servidores-correo/' + id + '/');
    }
  }

  putServidorCorreo(servidor: any): any {
    return this.http.put(this.apiUrl + 'servidores-correo/' + servidor.id + '/', servidor);
  }

  postServidorCorreo(servidor: any): any {
    return this.http.post(this.apiUrl + 'servidores-correo/', servidor);
  }

  deleteServidorCorreo(servidor: any): any {
    return this.http.delete(this.apiUrl + 'servidores-correo/' + servidor.id + '/');
  }

  getGestionFormasPago(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'gestion-formas-pago/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-formas-pago/' + id + '/');
    }
  }

  getGestionFormasPagoActivas(): any {
    return this.http.get(this.apiUrl + 'gestion-formas-pago/?activa=true');
  }

  putGestionFormasPago(fpago: any): any {
    return this.http.put(this.apiUrl + 'gestion-formas-pago/' + fpago.id + '/', fpago);
  }

  postGestionFormasPago(fpago: any): any {
    return this.http.post(this.apiUrl + 'gestion-formas-pago/', fpago);
  }

  deleteGestionFormasPago(fpago: any): any {
    return this.http.delete(this.apiUrl + 'gestion-formas-pago/' + fpago.id + '/');
  }


  getGestionPedidosProveedor(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/');
    }
  }

  getGestionPedidosProveedorProcesar(id: number | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/recepcionar_material/');
  }

  getPedidoProveedorNotificar(id: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/notificar/');
  }

  getPedidoProveedorPDF(id: any): any {
    return this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/descargar_pdf/';
  }

  getPedidoProveedorTotalizar(id: any): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/totalizar/');
  }

  putGestionPedidosProveedor(fpago: any): any {
    return this.http.put(this.apiUrl + 'gestion-pedidos-proveedor/' + fpago.id + '/', fpago);
  }

  postGestionPedidosProveedor(fpago: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos-proveedor/', fpago);
  }

  deleteGestionPedidosProveedor(fpago: any): any {
    return this.http.delete(this.apiUrl + 'gestion-pedidos-proveedor/' + fpago.id + '/');
  }

  putGestionPedidosProveedorProductos(pedido_id: any, producto: any): any {
    return this.http.put(this.apiUrl + 'gestion-pedidos-proveedor/' + pedido_id + '/productos-pedidos-proveedor/' + producto.id + '/', producto);
  }

  postGestionPedidosProveedorProductos(pedido_id: any, producto: any): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos-proveedor/' + pedido_id + '/productos-pedidos-proveedor/', producto);
  }

  deleteGestionPedidosProveedorProductos(pedido_id: any, id_producto: any): any {
    return this.http.delete(this.apiUrl + 'gestion-pedidos-proveedor/' + pedido_id + '/productos-pedidos-proveedor/' + id_producto + '/');
  }

  getGestionPedidosProveedorProductos(id: number | null, id_producto: any | null): any {
    if (id_producto == null) {
      return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/productos-pedidos-proveedor/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/productos-pedidos-proveedor/' + id_producto + '/');
    }
  }

  getGestionPedidosProveedorProductosRecepcionarUnidad(id: number | null, id_producto: any | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/productos-pedidos-proveedor/' + id_producto + '/recepcionar_unidad/');
  }

  postGestionPedidosProveedorProductosRecepcionParcial(id: number | null, id_producto: any | null, data: any | null): any {
    return this.http.post(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/productos-pedidos-proveedor/' + id_producto + '/recepcionar_unidad_seleccionada/', data);
  }

  totalizarGestionPedidosProveedorProductosRecepcionarUnidad(id: number | null, id_producto: any | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/' + id + '/productos-pedidos-proveedor/' + id_producto + '/totalizar/');
  }

  searchPedidosProveedor(ref: string | null): any {
    return this.http.get(this.apiUrl + 'gestion-pedidos-proveedor/?search=' + ref);
  }

  searchProductosByProveedor(busqueda: string | null, proveedor: string): any {
    return this.http.get(this.apiUrl + 'gestion-productos/?search=' + busqueda + "&feed_id=" + proveedor);
  }

  searchProductosByProveedorID(busqueda: string | null, proveedor: string): any {
    return this.http.get(this.apiUrl + 'gestion-productos/?search=' + busqueda + "&proveedor=" + proveedor);
  }

  /*Endpoints Transportistas*/
  getCarries(id: any | null): any {
    if (id != null) {
      return this.http.get(this.apiUrl + 'carriers/' + id + '/');
    } else {
      return this.http.get(this.apiUrl + 'carriers/');
    }
  }

  getCarriesAll(): any {
    return this.http.get(this.apiUrl + 'carriers/all/');
  }

  putCarries(carrier: any): any {
    return this.http.put(this.apiUrl + 'carriers/' + carrier.id + '/', carrier);
  }

  postCarries(carrier: any): any {
    return this.http.post(this.apiUrl + 'carriers/', carrier);
  }

  deleteCarries(carrier: any): any {
    return this.http.delete(this.apiUrl + 'carriers/' + carrier.id + '/');
  }

  /*Endpoints Transportistas Rates*/
  getCarrierRates(carrier_id: any, carrier_rates: any | null): any {
    if (carrier_rates != null) {
      return this.http.get(this.apiUrl + '/carriers/' + carrier_id + '/carrier-rates/' + carrier_rates.id + '/');
    } else {
      return this.http.get(this.apiUrl + '/carriers/' + carrier_id + '/carrier-rates/');
    }
  }

  putCarrierRates(carrier_id: any, carrier_rates: any): any {
    return this.http.put(this.apiUrl + 'carriers/' + carrier_id + '/carrier-rates/' + carrier_rates.id + '/', carrier_rates);
  }

  postCarrierRates(carrier_id: any, carrier_rates: any): any {
    return this.http.post(this.apiUrl + 'carriers/' + carrier_id + '/carrier-rates/', carrier_rates);
  }

  deleteCarrierRates(carrier_id: any, carrier_rates: any): any {
    return this.http.delete(this.apiUrl + 'carriers/' + carrier_id + '/carrier-rates/' + carrier_rates.id + '/');
  }

  postCalcularEnvio(data: any): any {
    return this.http.post(this.apiUrl + 'carriers/calcular_envio/', data);
  }

  postTarrifaCarrierValida(data: any): any {
    return this.http.post(this.apiUrl + 'carriers/tarifa_valida/', data);
  }

  getCMSPaginas(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'paginas/');
    } else {
      return this.http.get(this.apiUrl + 'paginas/' + id + '/');
    }
  }

  postCMSPaginas(usuario: any): any {
    return this.http.post(this.apiUrl + 'paginas/', usuario);
  }

  putCMSPaginas(usuario: any): any {
    return this.http.put(this.apiUrl + 'paginas/' + usuario.id + '/', usuario);
  }

  deleteCMSPaginas(usuario: any): any {
    return this.http.put(this.apiUrl + 'paginas/' + usuario.id + '/', usuario);
  }

  getCMSCategorias(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'categorias-paginas/');
    } else {
      return this.http.get(this.apiUrl + 'categorias-paginas/' + id + '/');
    }
  }

  getCMSCategoriasTodas(): any {
    return this.http.get(this.apiUrl + 'categorias-paginas/all/');
  }

  postCMSCategorias(usuario: any): any {
    return this.http.post(this.apiUrl + 'categorias-paginas/', usuario);
  }

  putCMSCategorias(usuario: any): any {
    return this.http.put(this.apiUrl + 'categorias-paginas/' + usuario.id + '/', usuario);
  }

  deleteCMSCategorias(usuario: any): any {
    return this.http.put(this.apiUrl + 'categorias-paginas/' + usuario.id + '/', usuario);
  }

  getCMSPosicionesCategorias(): any {
    return this.http.get(this.apiUrl + 'posiciones-pcats/');
  }

  getSesionTPVDetails(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'sesionTPV-details/');
    } else {
      return this.http.get(this.apiUrl + 'sesionTPV-details/' + id + '/');
    }
  }

  getSesionTPV(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'sesionTPV/');
    } else {
      return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/');
    }
  }

  postSesionTPV(sesionTPV: any): any {
    return this.http.post(this.apiUrl + 'sesionTPV/', sesionTPV);
  }

  putSesionTPV(sesionTPV: any): any {
    return this.http.put(this.apiUrl + 'sesionTPV/' + sesionTPV.id + '/', sesionTPV);
  }

  deleteSesionTPV(sesionTPV: any): any {
    return this.http.delete(this.apiUrl + 'sesionTPV/' + sesionTPV.id + '/');
  }

  getSesionTPVActual(): any {
    return this.http.get(this.apiUrl + 'sesionTPV/actual/');
  }


  getSesionTPVCerrar(id: number | null): any {
    return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/cerrar/');
  }

  getSesionTPVTotalizar(id: number | null): any {
    return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/totalizar/');
  }

  getSesionTPVVerTotalizar(id: number | null): any {
    return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/ver_totalizar/');
  }

  getSesionTPVAnotaciones(id: number | null, id_anotaciones: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/anotacionesTPV/');
    } else {
      return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/anotacionesTPV/' + id_anotaciones + '/');
    }
  }

  postSesionTPVAnotaciones(sesionTPV_id: any, anotacion: any | null): any {
    return this.http.post(this.apiUrl + 'sesionTPV/' + sesionTPV_id + '/anotacionesTPV/', anotacion);
  }

  putSesionTPVAnotaciones(sesionTPV_id: any, anotacion: any | null): any {
    return this.http.put(this.apiUrl + 'sesionTPV/' + sesionTPV_id + '/anotacionesTPV/' + anotacion.id + '/', anotacion);
  }

  deleteSesionTPVAnotaciones(sesionTPV_id: any, id_anotaciones: number | null): any {
    return this.http.delete(this.apiUrl + 'sesionTPV/' + sesionTPV_id + '/anotacionesTPV/' + id_anotaciones + '/');
  }

  postPedidoTPV(pedido: any): any {
    return this.http.post(this.apiUrl + 'pedido-tpv/', pedido);
  }


  getNextNumeroOrdenPedidoTPV(id: number): any {
    return this.http.get(this.apiUrl + 'pedidos/' + id + "/gen_numero_orden/");
  }

  postProductosPedidoTPV(id: number, produto: any): any {
    return this.http.post(this.apiUrl + 'pedidos/' + id + "/productos-pedidos/", produto);
  }

  getTicketPDF(id: number | null): any {
    return this.http.get(environment.URL_API + 'pedidos/' + id + '/ticket_pdf/');
  }

  getTicketPDFUrl(id: number | null): any {
    return environment.URL_API + 'pedidos/' + id + '/ticket_pdf/';
  }

  getTicketPDFBase64(id: number | null): any {
    return this.http.get(environment.URL_API + 'pedidos/' + id + '/ticket_pdf_base64/');
  }

  getSesionTPVPedidos(id: number | null): any {
    return this.http.get(this.apiUrl + 'sesionTPV/' + id + '/pedidos/');
  }

  getMisComisiones() {
    return this.http.get(this.apiUrl + 'solicitud-comisiones/solicitudes/');
  }

  getGenerarComisiones() {
    return this.http.get(this.apiUrl + 'solicitud-comisiones/generar_mes_actual/');
  }

  postGenerarComisiones(data: any) {
    return this.http.post(this.apiUrl + 'solicitud-comisiones/generar_mes_actual/', data);
  }

  getComisiones(id: number | null) {
    if (id != null) {
      return this.http.get(this.apiUrl + 'solicitud-comisiones/' + id + '/');
    } else {
      return this.http.get(this.apiUrl + 'solicitud-comisiones/');
    }
  }

  postComisiones(data: any) {
    return this.http.post(this.apiUrl + 'solicitud-comisiones/', data);
  }

  putComisiones(data: any) {
    return this.http.put(this.apiUrl + 'solicitud-comisiones/' + data.id + '/', data);
  }

  deleteComisiones(data_id: any) {
    return this.http.delete(this.apiUrl + 'solicitud-comisiones/' + data_id + '/');
  }
  //gestion de fichajes
  getCurrentFichaje() {
    return this.http.get(this.apiUrl + 'gestion-fichajes/get_current/');
  }

  postFichajeFilter(data: any) {
    return this.http.post(this.apiUrl + 'gestion-fichajes/filter/', data);
  }

  ReporteFichaje(data: any) {
    return this.apiUrl + 'gestion-fichajes/informe_pdf/?usuario=' + data.usuario + "&inicio=" + data.inicio + "&fin=" + data.fin;
  }

  getFichajes(id: number | null) {
    if (id != null) {
      return this.http.get(this.apiUrl + 'gestion-fichajes/' + id + '/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-fichajes/');
    }
  }

  postFichajes(data: any) {
    return this.http.post(this.apiUrl + 'gestion-fichajes/', data);
  }

  putFichajes(data: any) {
    return this.http.put(this.apiUrl + 'gestion-fichajes/' + data.id + '/', data);
  }

  deleteFichajes(data_id: any) {
    return this.http.delete(this.apiUrl + 'gestion-fichajes/' + data_id + '/');
  }

  //gestion impuestos
  getImpuestos(id: number | null) {
    if (id != null) {
      return this.http.get(this.apiUrl + 'gestion-impuestos/' + id + '/');
    } else {
      return this.http.get(this.apiUrl + 'gestion-impuestos/');
    }
  }

  //gestion impuestos
  getImpuestosActivos(id: number | null) {
    return this.http.get(this.apiUrl + 'gestion-impuestos/activos/');
  }

  //gestion impuestos
  getImpuestosTodos(id: number | null) {
    return this.http.get(this.apiUrl + 'gestion-impuestos/all/');
  }

  postImpuestos(data: any) {
    return this.http.post(this.apiUrl + 'gestion-impuestos/', data);
  }

  putImpuestos(data: any) {
    return this.http.put(this.apiUrl + 'gestion-impuestos/' + data.id + '/', data);
  }

  deleteImpuestos(data_id: any) {
    return this.http.delete(this.apiUrl + 'gestion-impuestos/' + data_id + '/');
  }

  //postsubir Archivos Locales
  postSubirFicheros(data: any) {
    return this.http.post(this.apiUrl + 'gestion-subir-datos/subir_archivo/', data);
  }

  //blog section
  getBlogPaginas(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'paginas-blog/');
    } else {
      return this.http.get(this.apiUrl + 'paginas-blog/' + id + '/');
    }
  }

  postBlogPaginas(usuario: any): any {
    return this.http.post(this.apiUrl + 'paginas-blog/', usuario);
  }

  putBlogPaginas(usuario: any): any {
    return this.http.put(this.apiUrl + 'paginas-blog/' + usuario.id + '/', usuario);
  }

  deleteBlogPaginas(usuario: any): any {
    return this.http.put(this.apiUrl + 'paginas-blog/' + usuario.id + '/', usuario);
  }

  getBlogCategorias(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'categorias-blog/');
    } else {
      return this.http.get(this.apiUrl + 'categorias-blog/' + id + '/');
    }
  }

  getBlogCategoriasTodas(): any {
    return this.http.get(this.apiUrl + 'categorias-blog/all/');
  }

  postBlogCategorias(usuario: any): any {
    return this.http.post(this.apiUrl + 'categorias-blog/', usuario);
  }

  putBlogCategorias(usuario: any): any {
    return this.http.put(this.apiUrl + 'categorias-blog/' + usuario.id + '/', usuario);
  }

  deleteBlogCategorias(usuario: any): any {
    return this.http.put(this.apiUrl + 'categorias-blog/' + usuario.id + '/', usuario);
  }

  getFaqs(id: number | null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'faqs/');
    } else {
      return this.http.get(this.apiUrl + 'faqs/' + id + '/');
    }
  }

  postFaqs(drop: any): any {
    return this.http.post(this.apiUrl + 'faqs/', drop);
  }

  putFaqs(drop: any): any {
    return this.http.put(this.apiUrl + 'faqs/' + drop.id + '/', drop);
  }

  deleteFaqs(id: number | null): any {
    return this.http.delete(this.apiUrl + 'faqs/' + id + '/');
  }

  //Gestion de los grupos Empresariales para agrupar usuarios
  getGrupoEmpresarial(id: number|null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'grupo-empresarial/');
    } else {
      return this.http.get(this.apiUrl + 'grupo-empresarial/' + id + '/');
    }
  }

  getGrupoEmpresarialAll(): any {
    return this.http.get(this.apiUrl + 'grupo-empresarial/all/');
  }

  postGrupoEmpresarial(gemp: any): any {
    return this.http.post(this.apiUrl + 'grupo-empresarial/', gemp);
  }

  putGrupoEmpresarial(gemp: any): any {
    return this.http.put(this.apiUrl + 'grupo-empresarial/' + gemp.id + '/', gemp);
  }

  deleteGrupoEmpresarial(id: number | null): any {
    return this.http.delete(this.apiUrl + 'grupo-empresarial/' + id + '/');
  }

  postGrupoEmpresarialFacturacion(gid: number, dataQuery:any): any {
    return this.http.post(this.apiUrl + 'grupo-empresarial/' + gid + '/facturacion/', dataQuery);
  }

  //Descuento por volumen
  getDescuentoVolumen(id: number|null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'descuento-por-volumen/');
    } else {
      return this.http.get(this.apiUrl + 'descuento-por-volumen/' + id + '/');
    }
  }

  postDescuentoVolumen(dvol: any): any {
    return this.http.post(this.apiUrl + 'descuento-por-volumen/', dvol);
  }

  putDescuentoVolumen(dvol: any): any {
    return this.http.put(this.apiUrl + 'descuento-por-volumen/' + dvol.id + '/', dvol);
  }

  deleteDescuentoVolumen(id: number | null): any {
    return this.http.delete(this.apiUrl + 'descuento-por-volumen/' + id + '/');
  }

  //subida de datos para migrar
  getSubidaDeDatos(id: number|null): any {
    if (id == null) {
      return this.http.get(this.apiUrl + 'subida-datos-task/');
    } else {
      return this.http.get(this.apiUrl + 'subida-datos-task/' + id + '/');
    }
  }

  postSubidaDeDatos(sdato: any): any {
    return this.http.post(this.apiUrl + 'subida-datos-task/', sdato);
  }

  putSubidaDeDatos(sdato: any): any {
    return this.http.put(this.apiUrl + 'subida-datos-task/' + sdato.id + '/', sdato);
  }

  deleteSubidaDeDatos(id: number | null): any {
    return this.http.delete(this.apiUrl + 'subida-datos-task/' + id + '/');
  }


}

