export class CarritoLinea {

    pedido = 0
    producto:any={}
    unidades:number=0.0
    total:number = 0.0
    
    calcTotal(){
        this.total = + parseFloat((this.producto.price*this.unidades).toFixed(2))
        return this.total.toFixed(2) 
    }

    calcImpuesto(){
        return (this.producto.impuestos*this.unidades).toFixed(2) 
    }

    calcNeto(){
        return (this.producto.importe*this.unidades).toFixed(2) 
    }

    calcMargen(){
        return (parseFloat(this.producto.precio_compra)*parseFloat(this.producto.margen))*this.unidades
    }
}
