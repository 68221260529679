import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-categoria',
  templateUrl: './add-categoria.component.html',
  styleUrls: ['./add-categoria.component.css']
})
export class AddCategoriaComponent implements OnInit {

  categoria:any={}
  valor_comision_comercial=0

  constructor(private api:ApiService,public dialogRef: MatDialogRef<AddCategoriaComponent>) { }

  ngOnInit(): void {
  }

  agregar(){
    //this.categoria.padre = this.categoriaSel.id
    //console.log(this.categoria)
    this.api.postGestionCategorias(this.categoria).subscribe((data:any)=>{
      this.dialogRef.close();
    });
  }

  setName(ev:any){
    this.categoria.nombre = ev.target.value
  }

  changeTheValue(event:any){
    this.valor_comision_comercial = event
    this.categoria['comision_comercial'] = event
  }

}
