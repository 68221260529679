<div class="mb-3 row">
  <label for="staticEmail" class="col-sm-2 col-form-label">Nombre</label>
  <div class="col-sm-10">
    <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="{{mensaje.nombre}}">
  </div>
</div>
<div class="mb-3 row">
  <label for="inputPassword" class="col-sm-2 col-form-label">Mensaje</label>
  <div class="col-sm-10">
    <textarea class="form-control" id="inputPassword" rows="5" readonly>{{mensaje.descripcion}}</textarea>
  </div>
</div>
<div class="mb-3 row">
  <label for="inputPassword" class="col-sm-2 col-form-label">Respuesta Mensaje</label>
  <div class="col-sm-10">
    <textarea class="form-control" id="inputPassword" rows="15" [(ngModel)]="respuesta.respuesta"></textarea>
  </div>
</div>
<div class="mb-3 row">
  <div class="btn-group" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-primary"  (click)="responder()">Responder</button>
  </div>
</div>
