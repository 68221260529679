import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    const pURL = environment.URL_API;
    const extraINFO = '/api/';
    //let endpoint=''
    if(baseUrl.includes("localhost")==false){
      if(baseUrl.includes("manager")){
        const domain = baseUrl.replace("manager","www")
        //console.log(domain)
        environment.URL_API = domain + extraINFO
        environment.MEDIA_URL = domain + extraINFO
      }
      if(baseUrl.includes("backoffice")){
        const domain = baseUrl.replace("backoffice","www")
        environment.URL_API = domain + extraINFO
        environment.MEDIA_URL = domain + extraINFO
      }
      if(baseUrl.includes("administracion")){
        const domain = baseUrl.replace("administracion","www")
        environment.URL_API = domain + extraINFO
        environment.MEDIA_URL = domain + extraINFO
      }
      if(baseUrl.includes("testmanager")){
        const domain = baseUrl.replace("testmanager","test")
        environment.URL_API = domain + extraINFO
        environment.MEDIA_URL = domain + extraINFO
      }
      if(baseUrl.includes("gestion")){
        const domain = baseUrl.replace("gestion","www")
        environment.URL_API = domain + extraINFO
        environment.MEDIA_URL = domain + extraINFO
    }
      if(baseUrl.includes("panel.ferrecasado.es")){
          const domain = baseUrl.replace("panel","store")
          environment.URL_API = domain + extraINFO
          environment.MEDIA_URL = domain + extraINFO
      }
      let newRequest = request.clone({ url: request.url.replace('https://www.teleca.es/api/', environment.URL_API) });
      //console.log(newRequest)
      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
