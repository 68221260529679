import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificarPedidoComponent } from '../notificar-pedido/notificar-pedido.component';

@Component({
  selector: 'app-asignar-pago-pedido',
  templateUrl: './asignar-pago-pedido.component.html',
  styleUrls: ['./asignar-pago-pedido.component.css']
})
export class AsignarPagoPedidoComponent implements OnInit {

  pagoID:string=""
  url:string=""
  pedido:any = {}
  errores = {"urlPago":null}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<AsignarPagoPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
   }

  ngOnInit(): void {
    if(this.pedido.pagoId){
      this.pagoID = this.pedido.pagoID
    }
    if(this.pedido.urlPago){
      this.url = this.pedido.urlPago
    }
  }

  guardarDatosPedido(){
    this.pedido.urlPago = this.url
    this.pedido.pagoId = this.pagoID
    this.api.putPedidos(this.pedido).subscribe((data:any)=>{
      this.dialogRef.close()
    })
  }

}
