import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-update-usuario',
  templateUrl: './update-usuario.component.html',
  styleUrls: ['./update-usuario.component.css']
})
export class UpdateUsuarioComponent implements OnInit {

  usuario:any={}
  tipoEmpresas:any[] = []

  constructor(private api:ApiService,public dialogRef: MatDialogRef<UpdateUsuarioComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data)
    this.usuario = data
   }

  ngOnInit(): void {
    this.api.getTipoEmpresas().subscribe((tipos:any[])=>{
      this.tipoEmpresas = tipos
    })
  }

  actualizar(){
    this.api.putParcialUsuario(this.usuario).subscribe((user:any)=>{
      console.log(user)
      this.dialogRef.close()
    })
  }

  cambiarTipoEmpresa(ev:any){
    this.usuario.tipo = ev.target.value
  }


}
