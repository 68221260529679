<div class="row gy-2 gx-3 align-items-center">
  <div class="col-auto">
    <label class="form-label" for="autoSizingSelect">Pais</label>
    <select class="form-select" id="autoSizingSelect" (input)="onChangeInput($event, 'pais')">
      <option>Seleciona uno</option>
      <option *ngFor="let pais of paises;" [selected]="rate.pais==pais.code" [value]="pais.code">{{pais.value}}</option>
    </select>
  </div>
  <div class="col-auto">
    <label class="form-label" for="autoSizingInput">Importe Minimo</label>
    <input type="text" class="form-control" id="autoSizingInput" placeholder="Importe Minimo"
      [(value)]="rate.importe_minimo" name="importe_minimo" (input)="onChangeInput($event, 'importe_minimo')">
  </div>
  <div class="col-auto">
    <label class="form-label" for="autoSizingInput">Importe Maximo</label>
    <input type="text" class="form-control" id="autoSizingInput" placeholder="Importe Maximo"
      [(value)]="rate.importe_maximo" name="importe_maximo" (input)="onChangeInput($event, 'importe_maximo')">
  </div>
  <div class="col-auto">
    <label class="form-label" for="autoSizingInput">Tarifa Envio Con Impuestos</label>
    <input type="text" class="form-control" id="autoSizingInput" placeholder="Tarifa Envio Con Impuestos"
      [(value)]="rate.tarifa_iva" name="tarifa_iva" (input)="onChangeInput($event, 'tarifa_iva')">
  </div>
  <div class="col-auto">
    <button type="button" class="btn btn-primary mt-4" (click)="agregarImporte()">Guardar</button>
  </div>
</div>
