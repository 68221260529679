import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificarPedidoComponent } from '../notificar-pedido/notificar-pedido.component';

@Component({
  selector: 'app-cambiar-tipo-pedido',
  templateUrl: './cambiar-tipo-pedido.component.html',
  styleUrls: ['./cambiar-tipo-pedido.component.css']
})
export class CambiarTipoPedidoComponent implements OnInit {

  tipoPedidos:any[] = []
  pedido:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<CambiarTipoPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
   }

  ngOnInit(): void {
    this.api.getCurrentUser().subscribe((user:any)=>{
      if(user.rol==1){
        this.api.tipoPedidoAdmins().subscribe((resp:any)=>{
          this.tipoPedidos = resp
        })
      }
      if(user.rol==2){
        this.api.tipoPedidoFreelances().subscribe((resp:any)=>{
          this.tipoPedidos = resp
        })
      }
    })
  }

  guardarDatosPedido(){
    this.api.putPedidos(this.pedido).subscribe(()=>{
      this.dialogRef.close()
    })
  }

  cambiarMetodoPago(ev:any){
    this.pedido.tipo_pedido = ev.target.value;
  }

}
