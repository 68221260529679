import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  usuario:any={}
  tipoEmpresas:any[] = []

  constructor(private api:ApiService,public dialogRef: MatDialogRef<AddUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    //console.log(data)
    this.usuario.rol = data
   }

  ngOnInit(): void {
    this.api.getTipoEmpresas().subscribe((tipos:any[])=>{
      this.tipoEmpresas = tipos
    })
  }

  crear(){
    this.usuario.username = this.usuario.email
    this.usuario.password = (Math.random() + 1).toString(36).substring(7);
    if(this.usuario.rol==1){
      this.usuario.is_superuser = true
      this.usuario.is_staff = true
    }
    this.api.postUsuario(this.usuario).subscribe((user:any)=>{
      this.api.notificarCreacioUsuario(user.id).subscribe((data:any)=>{
        this.dialogRef.close()
      })
    })
  }

  cambiarTipoEmpresa(ev:any){
    this.usuario.tipo = ev.target.value
  }

}
