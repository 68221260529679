<form (ngSubmit)="crearFaq()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Pregunta</label>
    <input type="text" class="form-control" [(ngModel)]="concepto.pregunta" name="pregunta"  id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Respuesta</label>
    <textarea type="text" class="form-control" [(ngModel)]="concepto.respuesta" name="respuesta" rows="15"></textarea>
  </div>
  <button type="submit" class="btn btn-primary">{{texto}}</button>
</form>
