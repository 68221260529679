import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { InfoMensajeComponent } from 'src/app/alerts/info-mensaje/info-mensaje.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-producto',
  templateUrl: './add-producto.component.html',
  styleUrls: ['./add-producto.component.css']
})
export class AddProductoComponent implements OnInit {

  linea: any = {};
  referencia: string = "";
  producto: any = {virtual:0};
  resultados: any[] = [];
  searhProducts = new Subject<any>();
  unidades: number = 0;
  pedido: any = {};
  descuento = 0.0;
  maxDescuento = 0.0;

  constructor(private api: ApiService,private snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddProductoComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.pedido = data;
    }
    this.searhProducts.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        //console.log(value);
        if (value.length > 3) {
          this.api.searchProductos(this.referencia).subscribe((data: any) => {
            this.resultados = data.results;
          });
        }
      });
  }

  ngOnInit(): void {
  }

  buscar() {
    this.api.searchProductos(this.referencia).subscribe((data: any) => {
      this.resultados = data.results;
    });
  }

  agregar() {
    this.api.getProductoDisponible(this.producto.id).subscribe((resp: any) => {
      if (resp.result == true) {
        this.api.postPedidosProductosComercial(this.pedido.id, { "pedido": this.pedido.id, "producto": this.producto.id, "descuentoLinea": this.descuento, "unidades": this.unidades }).subscribe((data: any) => {
          this.snackBar.openFromComponent(InfoMensajeComponent, {
            data: 'Linea añadida al pedido',
            duration: 1500,
            horizontalPosition: 'end',
            verticalPosition: 'top'
          })
          this.dialogRef.close();
        });
      }else{
        this.snackBar.openFromComponent(InfoMensajeComponent, {
          data: 'El Producto ' + this.producto.referencia + ' no se ha posido añadir',
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        })
      }
    })
  }

  selecionar(producto: any) {
    this.api.getProductoDisponible(producto.id).subscribe((resp: any) => {
      if (resp.result == true) {
        this.producto = producto;
        this.referencia = producto.referencia;
        this.maxDescuento = (this.producto.precio_compra * this.producto.margen) / 2
        //console.log(this.maxDescuento)
        this.resultados = [];
        this.snackBar.openFromComponent(InfoMensajeComponent, {
          data: 'Producto disponible',
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        })
      }else{
        this.snackBar.openFromComponent(InfoMensajeComponent, {
          data: 'El Producto ' + producto.referencia + ' no se ha posido añadir',
          duration: 1500,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        })
      }
    })

  }

  checkMaximun(ev: any) {
    if (ev > this.maxDescuento) {
      this.descuento = this.maxDescuento
    }
  }

  recallDiscount(ev: any) {
    if(this.producto.virtual>ev){
      this.unidades=this.producto.virtual
    }
    this.maxDescuento = ((this.producto.precio_compra * this.producto.margen) / 2) * this.unidades
  }
}