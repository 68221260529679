<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="slider.titulo"  [ngModelOptions]="{standalone: true}" name="titulo" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Sustitulo</label>
    <input [(ngModel)]="slider.subtitulo"  [ngModelOptions]="{standalone: true}" name="subtitulo" type="text" maxlength="6" class="form-control"
      id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Imagen</label>
    <input [(ngModel)]="slider.imagen"  [ngModelOptions]="{standalone: true}" name="imagen" type="file" (change)="selectImage($event)" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Url Destino</label>
    <input [(ngModel)]="slider.url"  [ngModelOptions]="{standalone: true}" name="url" type="url" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="form-group mb-3">
    <input class="form-check-input" type="checkbox" [(ngModel)]="slider.activo"  [ngModelOptions]="{standalone: true}" id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      Activo
    </label>
  </div>
  <button type="submit" class="btn btn-primary btn-sm">Agregar</button>
</form>
