<form (ngSubmit)="guardarDatosPedido()">
  <div class="mb-3">
    <!-- (ngModelChange)="recallDiscount($event)" -->
    <label for="exampleInputEmail1" class="form-label">Tipo de Pedido</label>
    <select class="form-select" aria-label="Default select example" (change)="cambiarMetodoPago($event)">
      <option *ngFor="let met of tipoPedidos" value="{{met.code}}" [selected]="met.code===pedido.tipo_pedido">
        {{met.value}}</option>
    </select>
  </div>
  <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="pedido.tipo_pedido==1||pedido.tipo_pedido==3">
    <strong>Atencion</strong> Si elige Factura o abono y pulsas en "Cambiar Metodo", se generaran los numeros de la
    factura o el abono correspondiente.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Cambiar Metodo</button>
</form>
