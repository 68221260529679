<form (ngSubmit)="guardarDatosPedido()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Url Carrier</label>
    <input [(ngModel)]="url" name="url" type="url" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Numero Seguimiento</label>
    <input [(ngModel)]="seguimiento" name="seguimiento" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Nombre Carrier</label>
    <input [(ngModel)]="carrier"  name="carrier" type="text" class="form-control" id="exampleInputEmail1">
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Guardar</button>
</form>
