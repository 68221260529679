<form (ngSubmit)="crear()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="usuario.first_name" name="first_name" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp" required>
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Apellidos</label>
    <input [(ngModel)]="usuario.last_name" name="last_name" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp" required>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Email</label>
    <input [(ngModel)]="usuario.email" name="email" type="email"
      class="form-control" id="exampleInputEmail1" required>
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Cif</label>
    <input [(ngModel)]="usuario.cif" name="cif" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <!-- (ngModelChange)="recallDiscount($event)" -->
    <label for="exampleInputEmail1" class="form-label">Tipo Empresa</label>
    <select class="form-select" aria-label="Default select example"
      (change)="cambiarTipoEmpresa($event)" required>
      <option *ngFor="let tip of tipoEmpresas" value="{{tip.code}}">{{tip.value}}</option>
    </select>
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" class="btn btn-primary btn-sm w-100">Agregar</button>
</form>
