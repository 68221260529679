import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificarPedidoComponent } from '../notificar-pedido/notificar-pedido.component';

@Component({
  selector: 'app-metodo-pago-pedido',
  templateUrl: './metodo-pago-pedido.component.html',
  styleUrls: ['./metodo-pago-pedido.component.css']
})
export class MetodoPagoPedidoComponent implements OnInit {

  pedido:any = {}
  metodo:string = ""

  constructor(private api:ApiService,public dialogRef: MatDialogRef<MetodoPagoPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.pedido = data
  }

  ngOnInit(): void {
  }

  crearUrlPago(){
    if(this.pedido.tipo_pedido==0){
      this.api.getPedidoCrearUrlPago(this.pedido.id).subscribe((resp:any)=>{
        console.log(resp)
      })
    }
  }

}
