<div class="row">
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Nombre</label>
    <div class="col-12">
      <input type="text" class="form-control" id="staticEmail" (keyup)="dynamicChange('nombre',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Url</label>
    <div class="col-12">
      <input type="url" class="form-control" id="staticEmail" (keyup)="dynamicChange('url',$event)" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Margen {{value}} %</label>
    <div class="col-12">
      <mat-slider min="0" max="2" step="0.10" value="0" showTickMarks="true" (valueChange)="changeTheValue($event)">
        <input matSliderThumb>
      </mat-slider>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Extension</label>
    <div class="col-12">
      <select class="form-select" aria-label="Default select example" (change)="dynamicChange('extension',$event)">
        <option *ngFor="let val of formatos" value="{{val}}">{{val}}</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Delimitador</label>
    <div class="col-12">
      <input type="text" class="form-control" id="staticEmail" value="{{dropshipper.delimitador}}" (keyup)="dynamicChange('delimitador',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Coste Extra</label>
    <div class="col-12">
      <input type="text" class="form-control" id="staticEmail" (keyup)="dynamicChange('coste_extra',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <button type="button" class="btn btn-sm btn-primary w-100" (click)="confirmar()" >Confirmar</button>
  </div>
</div>
