<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Alias</label>
    <input [(ngModel)]="direccion.alias"  name="alias" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
      <label for="exampleInputPassword1" class="form-label">Pais</label>
      <select class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon"
        [(ngModel)]="direccion.pais"  name="pais" (change)="changePais($event)" >
        <option *ngFor="let pa of paises" value="{{pa.code}}">{{pa.value}}</option>
      </select>
    </div>
    <div class="mb-3 col-6">
      <label for="exampleInputPassword1" class="form-label">Capital</label>
      <select class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon"
        (ngModel)="direccion.ciudad" name="ciudad" (change)="changeCapital($event)">
        <option *ngFor="let cap of capitales" value="{{cap.id}}" >{{cap.nombre}}</option>
      </select>
    </div>
    <div class="mb-3 col-6">
      <label for="exampleInputPassword1" class="form-label">Provincia</label>
      <select class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon"
        (ngModel)="direccion.provincia" name="provincia" (change)="selectCiudad($event)">
        <option *ngFor="let pro of provincias" value="{{pro.nombre}}">{{pro.nombre}}</option>
      </select>
    </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Codigo Postal</label>
    <input [(ngModel)]="direccion.codPostal"  name="codPostal" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Direccion</label>
    <input [(ngModel)]="direccion.calle"  name="calle" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Telefono</label>
    <input [(ngModel)]="direccion.telefono"  name="telefono" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Predeterminda</label><br>
    <input [(ngModel)]="direccion.predeterminada" type="checkbox" name="predeterminada" class="check-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Es Facturacion</label><br>
    <input [(ngModel)]="direccion.esFacturacion" type="checkbox" name="esFacturacion" class="check-control" id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" class="btn btn-primary btn-sm">Agregar</button>
</form>
