import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NotificarPedidoComponent } from '../notificar-pedido/notificar-pedido.component';

@Component({
  selector: 'app-notificar-pedido-proveedor',
  templateUrl: './notificar-pedido-proveedor.component.html',
  styleUrls: ['./notificar-pedido-proveedor.component.css']
})
export class NotificarPedidoProveedorComponent implements OnInit {

  pedido:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<NotificarPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.pedido=data
  }

  ngOnInit(): void {
  }

  notificarPedido(){
    this.api.getPedidoProveedorNotificar(this.pedido.id).subscribe((noti:any)=>{
      this.dialogRef.close()
    });
  }

}
