<mat-card>
<div class="alert alert-danger" role="alert" *ngIf="theError==true">
  Error de acceso usuario u contraseña inexistente o no dispone de permisos de acceso
</div>
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="login()" [formGroup]="loginForm">
      <p>
        <mat-form-field>
          <input type="text" matInput placeholder="Email" formControlName="username">
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input type="password" matInput placeholder="Password" formControlName="password">
        </mat-form-field>
      </p>

      <div class="button">
        <button type="submit" mat-button>Login</button>
      </div>

    </form>
  </mat-card-content>
</mat-card>
