<div class="mb-3 row">
  <label for="staticEmail" class="col-sm-2 col-form-label">Nombre</label>
  <div class="col-sm-10">
    <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="{{mensaje.nombre}}">
  </div>
</div>
<div class="mb-3 row">
  <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
  <div class="col-sm-10">
    <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="{{mensaje.email}}">
  </div>
</div>
<div class="mb-3 row">
  <label for="staticEmail" class="col-sm-2 col-form-label">Asunto</label>
  <div class="col-sm-10">
    <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="{{mensaje.asunto}}">
  </div>
</div>
<div class="mb-3 row">
  <label for="inputPassword" class="col-sm-2 col-form-label">Mensaje</label>
  <div class="col-sm-10">
    <textarea class="form-control" id="inputPassword" rows="15" readonly>{{mensaje.descripcion}}</textarea>
  </div>
</div>
<div class="mb-3 row">
  <div class="btn-group" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-primary"  (click)="cerrar()">Cerrar</button>
    <button type="button" class="btn btn-warning" (click)="SpamAndClose()">Marcar Spam y Cerrar</button>
    <button type="button" class="btn btn-info" (click)="NoSpamAndClose()">Desmarcar Spam y Cerrar</button>
    <button type="button" class="btn btn-danger" (click)="eliminarYCerrar()">Eliminar y Cerrar</button>
  </div>
</div>
