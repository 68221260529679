<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="slider.titulo" name="titulo" type="text" [ngModelOptions]="{standalone: true}" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Sustitulo</label>
    <input [(ngModel)]="slider.subtitulo" name="subtitulo" [ngModelOptions]="{standalone: true}" type="text" class="form-control"
      id="exampleInputEmail1" aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Imagen</label>
    <input [(ngModel)]="slider.imagenPre" name="imagenPre" [ngModelOptions]="{standalone: true}" type="file" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Imagen Preview</label>
    <img src="{{slider.imagen}}" class="form-control"/>
  </div>
  <div class="mb-3 col-12">
    <label for="exampleInputEmail1" class="form-label">Url Destino</label>
    <input [(ngModel)]="slider.url" name="url" type="url" class="form-control" [ngModelOptions]="{standalone: true}" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="form-group mb-3">
    <input class="form-check-input" type="checkbox" [(ngModel)]="slider.activo" [ngModelOptions]="{standalone: true}" id="flexCheckDefault">
    <label class="form-check-label" for="flexCheckDefault">
      Activo
    </label>
  </div>
  <button type="submit" class="btn btn-primary btn-sm">Agregar</button>
</form>

