import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelStatusComponent } from './label-status/label-status.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchSelectComponent } from './search-select/search-select.component';
import { ProgressBarLoaderComponent } from './progress-bar-loader/progress-bar-loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    LabelStatusComponent,
    SearchSelectComponent,
    ProgressBarLoaderComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatProgressBarModule
  ],
  exports:[
    LabelStatusComponent,
    SearchSelectComponent,
    ProgressBarLoaderComponent
  ]
})
export class ComponentesModule { }
