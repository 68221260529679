import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ConfigurarFormasPagoComponent } from '../configurar-formas-pago/configurar-formas-pago.component';

@Component({
  selector: 'app-grupo-empresarial-modal',
  templateUrl: './grupo-empresarial-modal.component.html',
  styleUrls: ['./grupo-empresarial-modal.component.css']
})
export class GrupoEmpresarialModalComponent implements OnInit {

  grupoEmpresaria:any = {nombre:"",cif:"",email:"",comisionVenta:0.0}
  editar:boolean = false
  texto:string = "Guardar"

  constructor(private api: ApiService, public dialogRef: MatDialogRef<GrupoEmpresarialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data!=null){
        this.grupoEmpresaria = data
        this.texto = "Actualizar"
        this.editar = true
      }
     }

  ngOnInit(): void {
  }

  onchangeData(field:string,ev:any){
    this.grupoEmpresaria[field] = ev.target.value
  }

  guardarDatos(){
    if(this.editar==true){
      this.api.putGrupoEmpresarial(this.grupoEmpresaria).subscribe((resp:any)=>{
        this.dialogRef.close()
      })
    }else{
      this.api.postGrupoEmpresarial(this.grupoEmpresaria).subscribe((resp:any)=>{
        this.dialogRef.close()
      })
    }

  }

}
