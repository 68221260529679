<div class="mb-3 row">
  <label for="staticEmail" class="col-12 col-form-label">Etiquetas a Imprimir</label>
  <div class="col-12">
    <input type="number" class="form-control" id="staticEmail" value="{{producto.stock}}" (change)="updateValor('numero', $event)">
  </div>
</div>
<div class="mb-3 row">
  <div class="btn-group" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-primary" (click)="cerrar()">Abrir Archivo y Cerrar</button>
  </div>
</div>

