import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-cierre-caja',
  templateUrl: './add-cierre-caja.component.html',
  styleUrls: ['./add-cierre-caja.component.css']
})
export class AddCierreCajaComponent implements OnInit {

  sesion:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<AddCierreCajaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.sesion = data
    console.log(data)
  }

  ngOnInit(): void {
  }

  updateValor(field:string, evento:any){
    this.sesion.sesion[field] = evento.target.value
  }

  cerrar(){
    delete this.sesion.usuario_name
    this.api.putSesionTPV(this.sesion.sesion).subscribe((resp:any)=>{
      this.dialogRef.close()
    })
  }

}
