import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-oferta',
  templateUrl: './add-oferta.component.html',
  styleUrls: ['./add-oferta.component.css']
})
export class AddOfertaComponent implements OnInit {

  oferta: any={porcentual:true}
  crear: boolean = true

  constructor(private api: ApiService, public dialogRef: MatDialogRef<AddOfertaComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    if(data!=null){
      this.oferta = data;
      this.crear = false;
    }
   }

  ngOnInit(): void {
    
  }

  agregar(){
    //console.log(this.oferta)
    if(this.crear==true){
      this.api.postCupones(this.oferta).subscribe((cupon: any) => {
        this.oferta = cupon;
        this.dialogRef.close()
      });
      
    }else{
      this.api.putCupones(this.oferta).subscribe((cupon: any) => {
        this.oferta = cupon;
        this.dialogRef.close()
      });
      this.dialogRef.close()
    }
  }

}
