import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-direccion',
  templateUrl: './add-direccion.component.html',
  styleUrls: ['./add-direccion.component.css']
})
export class AddDireccionComponent implements OnInit {

  pedido: any={}
  direccion:any={}
  paises:any[]= []
  capitales:any[]= []
  provincias:any[]= []


  constructor(private api: ApiService, public dialogRef: MatDialogRef<AddDireccionComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.pedido = data;
      //console.log(this.pedido)
   }

  ngOnInit(): void {
    this.api.getPaisesBasic().subscribe((paises:any)=>{
      this.paises = paises
    }) 
  }

  changePais(ev:any){
    let pais = ev.target.value
    this.api.getRegionesSearch(pais).subscribe((regiones: any) =>{
      this.capitales = regiones
    })
  }

  changeCapital(ev:any){
    let region_id = ev.target.value
    this.direccion.provincia =  this.capitales.filter((cap) => cap.id == region_id)[0].nombre;
    this.api.getCuidadesSearch(region_id).subscribe((ciudades: any) =>{
      this.provincias = ciudades
    })
  }

  selectCiudad(ev:any){
    this.direccion.ciudad = ev.target.value
  }

  agregar(){
    if(this.pedido.usuario.id!=null){
      this.direccion.usuario = this.pedido.usuario.id
    }
    if(this.pedido.usuario!=null){
      this.direccion.usuario = this.pedido.usuario
    }
    this.api.postUsuarioDirecciones(this.pedido.usuario.id,this.direccion).subscribe((cupon: any) => {
      this.dialogRef.close()
    });
  }


}
