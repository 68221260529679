import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mensaje-respuesta',
  templateUrl: './mensaje-respuesta.component.html',
  styleUrls: ['./mensaje-respuesta.component.css']
})
export class MensajeRespuestaComponent implements OnInit {

  mensaje:any = {}
  respuesta:any = {respuesta: ""}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<MensajeRespuestaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mensaje = data
   }

  ngOnInit(): void {
  }

  responder(){
    this.api.postContactosRespueta(this.mensaje.id, this.respuesta).subscribe((data:any)=>{
      this.dialogRef.close()
    })
    
  }

}
