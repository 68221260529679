import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortalModule } from '@angular/cdk/portal';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip'
import { LoginComponent } from './login/login.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConceptormaComponent } from './modals/conceptorma/conceptorma.component';
import { DropshipperComponent } from './modals/dropshipper/dropshipper.component';
import { AddProductoComponent } from './modals/add-producto/add-producto.component';
import { AddUserComponent } from './modals/add-user/add-user.component';
import { AddOfertaComponent } from './modals/add-oferta/add-oferta.component';
import { PedidoAddProductoComponent } from './modals/pedido-add-producto/pedido-add-producto.component';
import { SearchClientComponent } from './modals/search-client/search-client.component';
import { TrazaRespuestaComponent } from './modals/traza-respuesta/traza-respuesta.component';
import { MetodoPagoPedidoComponent } from './modals/metodo-pago-pedido/metodo-pago-pedido.component';
import { AsignarPagoPedidoComponent } from './modals/asignar-pago-pedido/asignar-pago-pedido.component';
import { AsignarInfoCarrierPedidoComponent } from './modals/asignar-info-carrier-pedido/asignar-info-carrier-pedido.component';
import { CambiarEstadoPedidoComponent } from './modals/cambiar-estado-pedido/cambiar-estado-pedido.component';
import { CambiarTipoPedidoComponent } from './modals/cambiar-tipo-pedido/cambiar-tipo-pedido.component';
import { NotificarPedidoComponent } from './modals/notificar-pedido/notificar-pedido.component';
import { CambiarMetodoPagoPedidoComponent } from './modals/cambiar-metodo-pago-pedido/cambiar-metodo-pago-pedido.component';
import { AddDireccionComponent } from './modals/add-direccion/add-direccion.component';
import { ModLineaComponent } from './modals/mod-linea/mod-linea.component';
import { AddCategoriaComponent } from './modals/add-categoria/add-categoria.component';
import { ModCategoriaComponent } from './modals/mod-categoria/mod-categoria.component';
import { AddCategoriaPadreComponent } from './modals/add-categoria-padre/add-categoria-padre.component';
import { CrearProductoComponent } from './modals/crear-producto/crear-producto.component';
import { CrearSlidersComponent } from './modals/crear-sliders/crear-sliders.component';
import { ModSlidersComponent } from './modals/mod-sliders/mod-sliders.component';
import { UpdateUsuarioComponent } from './modals/update-usuario/update-usuario.component';
import { MensajeDetalleComponent } from './modals/mensaje-detalle/mensaje-detalle.component';
import { MensajeRespuestaComponent } from './modals/mensaje-respuesta/mensaje-respuesta.component';
import { ModDireccionComponent } from './modals/mod-direccion/mod-direccion.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ConfigurarCorreoComponent } from './modals/configurar-correo/configurar-correo.component';
import { ConfigurarFormasPagoComponent } from './modals/configurar-formas-pago/configurar-formas-pago.component';
import { ConfigurarTransporteComponent } from './modals/configurar-transporte/configurar-transporte.component';
import { CarrierRatesConfiguracionComponent } from './modals/carrier-rates-configuracion/carrier-rates-configuracion.component';
import { SearchProveedorComponent } from './modals/search-proveedor/search-proveedor.component';
import { AddProductoProveedorComponent } from './modals/add-producto-proveedor/add-producto-proveedor.component';
import { ModProductoProveedorComponent } from './modals/mod-producto-proveedor/mod-producto-proveedor.component';
import { DelProductoProveedorComponent } from './modals/del-producto-proveedor/del-producto-proveedor.component';
import { RecepcionarProductosProveedorComponent } from './modals/recepcionar-productos-proveedor/recepcionar-productos-proveedor.component';
import { NotificarPedidoProveedorComponent } from './modals/notificar-pedido-proveedor/notificar-pedido-proveedor.component';
import { AsignarTransportePedidoComponent } from './modals/asignar-transporte-pedido/asignar-transporte-pedido.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImprimirEtiquetaComponent } from './modals/imprimir-etiqueta/imprimir-etiqueta.component';
import { AddCierreCajaComponent } from './modals/add-cierre-caja/add-cierre-caja.component';
import { AddDripshipperComponent } from './modals/add-dripshipper/add-dripshipper.component';
import { InfoMensajeComponent } from './alerts/info-mensaje/info-mensaje.component';
import { InfoListComponent } from './alerts/info-list/info-list.component';
import { ComponentesModule } from './componentes/componentes.module';
import { DelPedidoComponent } from './modals/del-pedido/del-pedido.component';
import { SendEmailToComponent } from './modals/send-email-to/send-email-to.component';
import { DomainInterceptor } from './services/domain.interceptor';
import { LoaderServiceInterceptor } from './services/loader-service.interceptor';
import { AddDescuentoComponent } from './modals/add-descuento/add-descuento.component';
import { AddDescuentoGlobalComponent } from './modals/add-descuento-global/add-descuento-global.component';
import { FichajeComponent } from './modals/fichaje/fichaje.component';
import { DatePipe } from '@angular/common';
import { FaqModalComponent } from './modals/faq-modal/faq-modal.component';
import { DeleteProductoComponent } from './modals/delete-producto/delete-producto.component';
import { PedidoMensajesComponent } from './modals/pedido-mensajes/pedido-mensajes.component';
import { DescuentoPorVolumenComponent } from './modals/descuento-por-volumen/descuento-por-volumen.component';
import { GrupoEmpresarialModalComponent } from './modals/grupo-empresarial-modal/grupo-empresarial-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConceptormaComponent,
    DropshipperComponent,
    AddProductoComponent,
    AddUserComponent,
    AddOfertaComponent,
    PedidoAddProductoComponent,
    SearchClientComponent,
    TrazaRespuestaComponent,
    MetodoPagoPedidoComponent,
    AsignarPagoPedidoComponent,
    AsignarInfoCarrierPedidoComponent,
    CambiarEstadoPedidoComponent,
    CambiarTipoPedidoComponent,
    NotificarPedidoComponent,
    CambiarMetodoPagoPedidoComponent,
    AddDireccionComponent,
    ModLineaComponent,
    AddCategoriaComponent,
    ModCategoriaComponent,
    AddCategoriaPadreComponent,
    CrearProductoComponent,
    CrearSlidersComponent,
    ModSlidersComponent,
    UpdateUsuarioComponent,
    MensajeDetalleComponent,
    MensajeRespuestaComponent,
    ModDireccionComponent,
    ConfigurarCorreoComponent,
    ConfigurarFormasPagoComponent,
    ConfigurarTransporteComponent,
    CarrierRatesConfiguracionComponent,
    SearchProveedorComponent,
    AddProductoProveedorComponent,
    ModProductoProveedorComponent,
    DelProductoProveedorComponent,
    RecepcionarProductosProveedorComponent,
    NotificarPedidoProveedorComponent,
    AsignarTransportePedidoComponent,
    ImprimirEtiquetaComponent,
    AddCierreCajaComponent,
    AddDripshipperComponent,
    InfoMensajeComponent,
    InfoListComponent,
    DelPedidoComponent,
    SendEmailToComponent,
    AddDescuentoComponent,
    AddDescuentoGlobalComponent,
    FichajeComponent,
    FaqModalComponent,
    DeleteProductoComponent,
    PedidoMensajesComponent,
    DescuentoPorVolumenComponent,
    GrupoEmpresarialModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    FontAwesomeModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    MatBadgeModule,
    MatGridListModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTooltipModule,
    HighchartsChartModule,
    CKEditorModule,
    FontAwesomeModule,
    ComponentesModule
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DomainInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderServiceInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
