import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { CambiarTipoPedidoComponent } from '../cambiar-tipo-pedido/cambiar-tipo-pedido.component';

@Component({
  selector: 'app-cambiar-estado-pedido',
  templateUrl: './cambiar-estado-pedido.component.html',
  styleUrls: ['./cambiar-estado-pedido.component.css']
})
export class CambiarEstadoPedidoComponent implements OnInit {

  estados:any[]=[]
  pedido:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<CambiarEstadoPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
   }

  ngOnInit(): void {
    this.api.estadosPedido().subscribe((estados:any[])=>{
      this.estados = estados
    })
  }

  guardarDatosPedido(){
    this.api.putPedidos(this.pedido).subscribe((data:any)=>{
      this.dialogRef.close()
    })

  }

  cambiarEstadoPedido(ev:any){
    this.pedido.estado = ev.target.value
  }

}
