<form (ngSubmit)="actualizar()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="linea.descripcion" name="descripcion" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp" readonly>
  </div>
  <div class="row">
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Referencia</label>
      <input [(ngModel)]="linea.referencia" name="referencia" type="text" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp" readonly>
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Precio Compra</label>
      <input [(ngModel)]="linea.precio_compra" name="precio_compra" type="text" class="form-control"
        id="exampleInputEmail1" aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Impuestos (%)</label>
      <input [(ngModel)]="linea.impuestos" name="impuestos" type="text" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Unidades</label>
      <input [(ngModel)]="linea.unidades" name="unidades" type="number" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-12">
      <label for="exampleInputEmail1" class="form-label">Descuento</label>
      <input [(ngModel)]="linea.descuentoLinea" name="descuentoLinea" type="number" step="0.1" [max]="maxDescuento"
        class="form-control" id="exampleInputEmail1">
    </div>
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" [disabled]="linea.unidades <= 0" class="btn btn-primary btn-sm w-100">Modificar</button>
</form>
