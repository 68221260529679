import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { MetodoPagoPedidoComponent } from '../metodo-pago-pedido/metodo-pago-pedido.component';

@Component({
  selector: 'app-cambiar-metodo-pago-pedido',
  templateUrl: './cambiar-metodo-pago-pedido.component.html',
  styleUrls: ['./cambiar-metodo-pago-pedido.component.css']
})
export class CambiarMetodoPagoPedidoComponent implements OnInit {

  pedido: any = {};
  metodos: any[] = []

  constructor(private api: ApiService, public dialogRef: MatDialogRef<CambiarMetodoPagoPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
  }

  ngOnInit(): void {
    this.api.getFormasPago().subscribe((metodos: any[]) => {
      this.metodos = metodos
    })
  }

  guardarDatosPedido() {
    this.api.putPedidos(this.pedido).subscribe((resp: any) => {
      this.dialogRef.close()
    })
  }

  cambiarMetodoPago(ev: any) {
    this.pedido.pasarelaPago = ev.target.value
  }

}
