import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-asignar-info-carrier-pedido',
  templateUrl: './asignar-info-carrier-pedido.component.html',
  styleUrls: ['./asignar-info-carrier-pedido.component.css']
})
export class AsignarInfoCarrierPedidoComponent implements OnInit {

  carrier:string=""
  seguimiento:string=""
  url:string=""
  lineaPedido:any = {}


  constructor(private api:ApiService,public dialogRef: MatDialogRef<AsignarInfoCarrierPedidoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.lineaPedido = data
    this.url = this.lineaPedido.transportista_url
    this.seguimiento = this.lineaPedido.numeroSeguimiento
    this.carrier = this.lineaPedido.transportista
  }

  ngOnInit(): void {
  }

  guardarDatosPedido(){
    this.lineaPedido.transportista_url = this.url
    this.lineaPedido.numeroSeguimiento = this.seguimiento
    this.lineaPedido.transportista = this.carrier
    this.api.putPedidoProductos(this.lineaPedido).subscribe(()=>{
      this.dialogRef.close()
    })
  }

}
