import {
  CarritoLinea
} from "./carrito-linea"

export class Carrito {

  products: CarritoLinea[] = []
  impuestos: number = 0
  importe: number = 0
  final: number = 0
  tipo_pedido = 0
  estado = 0
  denvio = null
  facturacion = null
  usuario = null
  pasarelaPago:string = 'efectivo'
  origen:string = 'Tienda'
  vendedor:number = 0
  descuentoGlobal:number = 0

  addProducto(linea: any) {
    console.log(linea)
    var existe = this.existProd(linea)
    if(linea.virtual>0 || linea.stock>0){
    if (existe == false) {
        let cl = new CarritoLinea()
        cl.producto = linea
        cl.unidades = 1
        cl.calcTotal()
        this.products.push(cl)
        if(linea.virtual>0){
          linea.virtual = linea.virtual - 1
        }
        if(linea.stock>0){
          linea.stock = linea.stock - 1
        }
    } else {
        let index = this.products.findIndex(prod => prod.producto.id === linea.id);
        this.products[index].unidades = this.products[index].unidades + 1
        this.products[index].calcTotal()
        if(linea.virtual>0){
          linea.virtual = linea.virtual - 1
        }
        if(linea.stock>0){
          linea.stock = linea.stock - 1
        }
    }
  }
    this.totalizar()
  }

  existProd(prod: any) {
    var data = this.products.filter((item) => item.producto.id == prod.id)
    if (data.length > 0) {
      return true
    } else {
      return false
    }
  }

  totalizar() {
    this.final = 0
    this.impuestos = 0
    this.importe = 0
    this.products.forEach(element => {
      this.final = this.final + parseFloat(element.calcTotal())
      this.impuestos = this.impuestos + parseFloat(element.calcImpuesto())
      this.importe = this.importe + parseFloat(element.calcNeto())
    });
    this.final = parseFloat(this.final.toFixed(2)) - parseFloat(this.descuentoGlobal.toFixed(2))
    this.impuestos = parseFloat(this.impuestos.toFixed(2))
    this.importe = parseFloat(this.importe.toFixed(2))
  }

  deleteProducto(numero_linea: number) {
    this.products.splice(numero_linea, 1)
    this.totalizar()
  }

  limpiarCarrito() {
    this.products = []
    this.descuentoGlobal = 0
    this.totalizar()
  }

  descuentoMaximo() {
    let descuento = 0
    this.products.forEach(linea => {
      descuento = descuento + linea.calcMargen()
    });
    return parseFloat((descuento*0.8).toFixed(2))
  }
}
