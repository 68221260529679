import { Component, OnInit } from '@angular/core';
import { LoadingServiceService } from 'src/app/services/loading-service.service';

@Component({
  selector: 'app-progress-bar-loader',
  templateUrl: './progress-bar-loader.component.html',
  styleUrls: ['./progress-bar-loader.component.css']
})
export class ProgressBarLoaderComponent implements OnInit {

  constructor(public loader: LoadingServiceService) { }

  ngOnInit(): void {
  }

}
