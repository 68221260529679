<div class="row">
  <div *ngIf="accion==0">
    ¿Confirmar el pedido: {{pedido.id}}? Se Descontaran las unidades si no fueron descontadas y se confirmara por
    completo.<br>
    <button type="button" class="btn btn-sm btn-success w-100" (click)="accionPedido()">Confirmar Pedido</button>
  </div>
  <div *ngIf="accion==1">
    ¿Anular el pedido: {{pedido.id}}? Se agregaran las unidades nuevamente al stock y se marcara como anulado.<br>
    <button type="button" class="btn btn-sm btn-danger w-100" (click)="accionPedido()">Anular Pedido</button>
  </div>
</div>
