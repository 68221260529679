import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AddProductoComponent } from '../add-producto/add-producto.component';

@Component({
  selector: 'app-search-proveedor',
  templateUrl: './search-proveedor.component.html',
  styleUrls: ['./search-proveedor.component.css']
})
export class SearchProveedorComponent implements OnInit {


  referencia: string = "";
  proveedor:any={};
  resultados:any[]=[];
  searhProducts = new Subject<string>();

  constructor(private api: ApiService,public dialogRef: MatDialogRef<SearchProveedorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.searhProducts.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        console.log(value);
        if(value.length > 2){
          this.api.searchProveedor(this.referencia).subscribe((data:any)=>{
            console.log(data)
            this.resultados = data.results;
          });
        }
      });
  }

  ngOnInit(): void {
  }

  buscar(){
    this.api.searchProveedor(this.referencia).subscribe((data:any)=>{
      this.resultados = data.results;
    });
  }

  agregar(){
    console.log(this.proveedor)
    this.dialogRef.close(this.proveedor);
  }

  selecionar(proveedor:any){
    this.referencia = proveedor.nombre
    this.proveedor = proveedor
    this.resultados = [];
  }
}
