import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  CambiarMetodoPagoPedidoComponent
} from '../cambiar-metodo-pago-pedido/cambiar-metodo-pago-pedido.component';

@Component({
  selector: 'app-asignar-transporte-pedido',
  templateUrl: './asignar-transporte-pedido.component.html',
  styleUrls: ['./asignar-transporte-pedido.component.css']
})
export class AsignarTransportePedidoComponent implements OnInit {

  pedido: any = {};
  metodos: any[] = []

  constructor(private api: ApiService, public dialogRef: MatDialogRef < AsignarTransportePedidoComponent > , @Inject(MAT_DIALOG_DATA) public data: any) {
    this.pedido = data
  }

  ngOnInit(): void {
    this.api.getCarries(null).subscribe((metodos: any) => {
      this.metodos = metodos.results
    })
  }

  guardarDatosPedido() {
    this.api.putPedidos(this.pedido).subscribe((resp: any) => {
      this.api.calcularImporteTransporte(resp).subscribe((resp: any) => {
        this.dialogRef.close()
      })
    })
  }

  cambiarCarrier(ev: any) {
    this.pedido.carrier = ev.target.value
  }

}
