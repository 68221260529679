import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-conceptorma',
  templateUrl: './conceptorma.component.html',
  styleUrls: ['./conceptorma.component.css']
})
export class ConceptormaComponent implements OnInit {

  concepto: any = {};
  actualizar: boolean = false;
  texto:string = "Crear";

  constructor(private api: ApiService, public dialogRef: MatDialogRef<ConceptormaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.concepto = data;
      this.actualizar = true;
      this.texto = "Actualizar";
    }
  }

  ngOnInit(): void {
  }

  crearConcepto() {
    if (this.actualizar == false) {
      this.api.postConceptosRma(this.concepto).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    } else {
      this.api.putConceptosRma(this.concepto).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    }
  }

}
