import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-del-pedido',
  templateUrl: './del-pedido.component.html',
  styleUrls: ['./del-pedido.component.css']
})
export class DelPedidoComponent implements OnInit {

  pedido:any = {}

  constructor(private api: ApiService,private snackBar: MatSnackBar, public dialogRef: MatDialogRef<DelPedidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.pedido = data
    }

  ngOnInit(): void {
  }

  eliminar(){
    this.api.eliminarPedidoGestion(this.pedido.id).subscribe((resp:any)=>{
      this.dialogRef.close()
    })
  }

  cerrar(){
    this.dialogRef.close()
  }



}
