import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ModProductoProveedorComponent } from '../mod-producto-proveedor/mod-producto-proveedor.component';

@Component({
  selector: 'app-recepcionar-productos-proveedor',
  templateUrl: './recepcionar-productos-proveedor.component.html',
  styleUrls: ['./recepcionar-productos-proveedor.component.css']
})
export class RecepcionarProductosProveedorComponent implements OnInit {

  pedido: any = {};
  productos: any = []
  indices: any = []

  constructor(private api: ApiService, public dialogRef: MatDialogRef<RecepcionarProductosProveedorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.pedido = data
  }

  ngOnInit(): void {
    this.api.getGestionPedidosProveedorProductos(this.pedido.id,null).subscribe((pro:any)=>{
      this.productos = pro
    })
  }

  selecionar(linea:any){
    let indice = this.indices.indexOf(linea.id)
    console.log(indice)
    if(linea.selecionada==null){
      if(this.indices.indexOf(linea.id) == -1) {
        this.indices.push(linea.id)
        linea.selecionada = true
      }
    }else{
      if(linea.selecionada == true){
        let indice = this.indices.indexOf(linea.id)
        this.indices.splice(indice,1)
        linea.selecionada = false
      }else{
        if(this.indices.indexOf(linea.id) == -1) {
          this.indices.push(linea.id)
          linea.selecionada = true
        }
      }
    }
    console.log(this.indices)
  }

  recepcionarUnidad() {
    let recepciones=[]
    for (let index = 0; index < this.indices.length; index++) {
      const element = this.indices[index];
      recepciones.push(this.api.getGestionPedidosProveedorProductosRecepcionarUnidad(this.pedido.id,element))
    }
    forkJoin(recepciones).subscribe((results:any[]) => {
      this.dialogRef.close()
    }) 
  }

  recepcionarPedido() {
    this.api.getGestionPedidosProveedorProcesar(this.pedido.id).subscribe((resp:any)=>{
      this.dialogRef.close()
    })
  }

  recepcionParcial(){
    let recepciones:any[]=[]
    this.indices.forEach((indice:any) => {
      var linea = this.productos.filter((lp:any) => lp.id == indice);
      if(linea.length==1){
        var recibido = {"unidades": linea[0].unidadesRecepcionadas}
        recepciones.push(this.api.postGestionPedidosProveedorProductosRecepcionParcial(this.pedido.id,linea[0].id,recibido))
      }
    }); 
      
    forkJoin(recepciones).subscribe((results:any[]) => {
      this.dialogRef.close()
    })
  }

  changeUnids(linea:any,campo:string,ev:any){
    linea[campo] = ev.target.value
  }

  filterLinea(id:number){

  }

  cerrar(){
    this.dialogRef.close()
  }
}