import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Carrito } from 'src/app/models/carrito';

@Component({
  selector: 'app-add-descuento',
  templateUrl: './add-descuento.component.html',
  styleUrls: ['./add-descuento.component.css']
})
export class AddDescuentoComponent implements OnInit {

  carrito: Carrito = new Carrito()
  descuento:number = 0
  descuentoMaximo:number = 0

  constructor(public dialogRef: MatDialogRef < AddDescuentoComponent > , @Inject(MAT_DIALOG_DATA) public data: Carrito) {
    this.carrito = data
  }

  ngOnInit(): void {
    this.descuentoMaximo = this.carrito.descuentoMaximo()
    console.log(this.descuentoMaximo)
  }

  agregar() {
    console.log(this.descuento)
    if(this.descuento<=this.descuentoMaximo){
      this.carrito.descuentoGlobal = this.descuento
    }else{
      this.carrito.descuentoGlobal = this.descuentoMaximo
    }
    this.dialogRef.close()
  }


}
