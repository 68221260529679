<form (ngSubmit)="guardarDatosPedido()">
  <div class="mb-3">
    <!-- (ngModelChange)="recallDiscount($event)" -->
    <label for="exampleInputEmail1" class="form-label">Estado Pedido</label>
    <select class="form-select" aria-label="Default select example"
      (change)="cambiarEstadoPedido($event)">
      <option *ngFor="let met of estados" value="{{met.code}}" [selected]="met.code===pedido.estado">{{met.value}}</option>
    </select>
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Cambiar Metodo</button>
</form>
