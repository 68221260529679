import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-email-to',
  templateUrl: './send-email-to.component.html',
  styleUrls: ['./send-email-to.component.css']
})
export class SendEmailToComponent implements OnInit {

  pedido:any = {}
  dataEmails:any={"destinatarios":[]}
  emailString=''
  usuario = {"email": "Sin Email asignado"}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<SendEmailToComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.pedido=data
    this.api.getUsuario(this.pedido.usuario).subscribe((data:any)=>{
      this.usuario = data
    })
  }

  ngOnInit(): void {
  }

  setText(ev:any){
    this.emailString = ev.target.value
  }

  notificarPedido(){
    this.dataEmails["destinatarios"] = this.emailString.split(';')
    this.api.postNotificarPresupuesto(this.pedido.id,this.dataEmails).subscribe((noti:any)=>{
      console.log(noti)
      this.dialogRef.close()
    });
  }

}
