<div class="row">
  <div class="mb-3 col-12">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input type="email" [(ngModel)]="categoria.nombre" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Comision Comercial ({{valor_comision_comercial}} %)</label>
    <div class="col-12">
      <mat-slider min="0" max="80" step="0.10" value="{{categoria.comision_comercial}}" showTickMarks="true" (valueChange)="changeTheValue($event)">
        <input matSliderThumb>
      </mat-slider>
    </div>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" [(ngModel)]="categoria.principal" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Principal</label>
    <div id="emailHelp" class="form-text">Se muestra en el menu principal del ecommerce</div>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" [(ngModel)]="categoria.trending" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Trending Topick</label>
    <div id="emailHelp" class="form-text">La pagina principal, los productos destacados</div>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" [(ngModel)]="categoria.activo" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Activa</label>
    <div id="emailHelp" class="form-text">Categoria Visible o no</div>
  </div>
  <button type="button" class="btn btn-primary" (click)="actualizar()">Actualizar</button>
</div>
