import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  ConceptormaComponent
} from '../conceptorma/conceptorma.component';

@Component({
  selector: 'app-configurar-formas-pago',
  templateUrl: './configurar-formas-pago.component.html',
  styleUrls: ['./configurar-formas-pago.component.css']
})
export class ConfigurarFormasPagoComponent implements OnInit {

  formasPagoBase: any = {}
  formaPago: any = {}
  actualizar: boolean = false
  texto: string = "Crear"

  constructor(private api: ApiService, public dialogRef: MatDialogRef < ConfigurarFormasPagoComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.formaPago = data
      this.actualizar = true
      this.texto = "Actualizar"
    }
  }

  ngOnInit(): void {
    this.api.getFormasPago().subscribe((resp: any) => {
      this.formasPagoBase = resp
    })
  }

  agregar() {
    if (this.actualizar == false) {
      this.api.postGestionFormasPago(this.formaPago).subscribe((resp: any) => {
        this.dialogRef.close()
      }, (error: any) => {
        console.error(error)
      });
    } else {
      this.api.putGestionFormasPago(this.formaPago).subscribe((resp: any) => {
        this.dialogRef.close()
      }, (error: any) => {
        console.error(error)
      });
    }
  }

}
