import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-imprimir-etiqueta',
  templateUrl: './imprimir-etiqueta.component.html',
  styleUrls: ['./imprimir-etiqueta.component.css']
})
export class ImprimirEtiquetaComponent implements OnInit {

  producto:any = {}
  dataPrint:any = {numero: 0}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<ImprimirEtiquetaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.producto = data
    this.dataPrint.numero = this.producto.stock
   }

  ngOnInit(): void {
  }

  updateValor(field:string, ev:any){
    this.dataPrint[field] = ev.target.value
  }

  cerrar(){
    window.open(this.api.imprimirEtiquetaProductoGet(this.producto.id,this.dataPrint.numero), '_blank')
    this.dialogRef.close()
  }

}
