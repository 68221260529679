import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.css']
})
export class FaqModalComponent implements OnInit {

  concepto: any = {};
  actualizar: boolean = false;
  texto:string = "Crear";

  constructor(private api: ApiService, public dialogRef: MatDialogRef<FaqModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    if (data != null) {
      this.concepto = data;
      this.actualizar = true;
      this.texto = "Actualizar";
    }
  }

  ngOnInit(): void {
  }

  crearFaq() {
    if (this.actualizar == false) {
      this.api.postFaqs(this.concepto).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    } else {
      this.api.putFaqs(this.concepto).subscribe((resp: any) => {
        this.dialogRef.close();
      });
    }
  }

}
