import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pedido-add-producto',
  templateUrl: './pedido-add-producto.component.html',
  styleUrls: ['./pedido-add-producto.component.css']
})
export class PedidoAddProductoComponent implements OnInit {

  linea: any = {};
  referencia: string = "";
  producto: any = {};
  resultados: any[] = [];
  searhProducts = new Subject<string>();
  unidades: number = 0;
  pedido: any = {};
  adjuntar = true
  lineaNumero=0;
  descuento = 0.0;
  maxDescuento=0.0;

  constructor(private api: ApiService, public dialogRef: MatDialogRef<PedidoAddProductoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data!=null){
      this.referencia = data.producto.referencia
      this.unidades = data.unidades
      this.adjuntar = false
      this.lineaNumero = data.linea
      this.producto = data.producto
    }
    this.searhProducts.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (value.length > 3) {
          this.api.searchProductos(this.referencia).subscribe((data: any) => {
            this.resultados = data.results;
          });
        }
      });
  }

  ngOnInit(): void {
  }

  buscar() {
    this.api.searchProductos(this.referencia).subscribe((data: any) => {
      this.resultados = data.results;
    });
  }

  agregar() {
    if(this.adjuntar==true){
      this.dialogRef.close({"pedido": null, "producto": this.producto.id, "unidades": this.unidades,"descuentoLinea": this.descuento});
    }else{
      this.dialogRef.close({"pedido": null, "producto": this.producto.id, "unidades": this.unidades,"descuentoLinea": this.descuento, "linea": this.lineaNumero });
    }
    
  }

  selecionar(producto: any) {
    this.producto = producto;
    this.referencia = producto.referencia;
    this.maxDescuento = (this.producto.precio_compra*this.producto.margen)/2
    console.log(this.maxDescuento)
    this.resultados = [];
  }

  checkMaximun(ev:any){
    if(ev>this.maxDescuento){
      this.descuento = this.maxDescuento
    }
  }

  recallDiscount(ev:any){
    console.log(ev)
    this.maxDescuento = ((this.producto.precio_compra*this.producto.margen)/2)*this.unidades
    console.log(this.maxDescuento)
  }

}