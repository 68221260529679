import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mensaje-detalle',
  templateUrl: './mensaje-detalle.component.html',
  styleUrls: ['./mensaje-detalle.component.css']
})
export class MensajeDetalleComponent implements OnInit {

  mensaje:any = {}

  constructor(private api:ApiService,public dialogRef: MatDialogRef<MensajeDetalleComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mensaje = data
   }

  ngOnInit(): void {
  }

  cerrar(){
    this.dialogRef.close()
  }

  SpamAndClose(){
    this.mensaje.es_spam = true
    this.api.putContactos(this.mensaje).subscribe((data:any)=>{
      this.dialogRef.close()
    })
  }

  NoSpamAndClose(){
    this.mensaje.es_spam = false
    this.api.putContactos(this.mensaje).subscribe((data:any)=>{
      this.dialogRef.close()
    })
  }

  eliminarYCerrar(){
    this.api.deleteContactos(this.mensaje.id).subscribe((data:any)=>{
      this.dialogRef.close()
    })
  }

}
