<form (ngSubmit)="notificarPedido()">
  <div class="mb-2">
    <p *ngIf="pedido.tipo_pedido==0">El pedido <strong>{{pedido.numeroPedido}}</strong> se enviara al cliente mediante email</p>
    <p *ngIf="pedido.tipo_pedido==2">El presupuesto <strong>{{pedido.numeroPresupuesto}}</strong> se enviara al cliente mediante email</p>
    <p *ngIf="pedido.tipo_pedido==3">El abono <strong>{{pedido.numeroAbono}}</strong> se enviara al cliente mediante email</p>
    <p *ngIf="pedido.tipo_pedido==1">La factura <strong>{{pedido.numeroFactura}}</strong> se enviara al cliente mediante email</p>
  </div>
  <div class="mb-3">
    <p>El Email se enviara a <strong>{{usuario.email}}</strong> independientemente de que pongas mas correos</p>
    <p>Emails de Clientes adicionales a enviar el Presupuesto(separe cada email con <strong>";"</strong>)</p>
  </div>
  <div class="mb-3">
     <input type="text" class="form-control" placeholder="Email1;Email2" (keyup)="setText($event)">
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Notificar</button>
</form>
