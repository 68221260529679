import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AddDireccionComponent } from '../add-direccion/add-direccion.component';

@Component({
  selector: 'app-mod-direccion',
  templateUrl: './mod-direccion.component.html',
  styleUrls: ['./mod-direccion.component.css']
})
export class ModDireccionComponent implements OnInit {

  direccion:any={}
  paises:any[]= []
  capitales:any[]= []
  provincias:any[]= []


  constructor(private api: ApiService, public dialogRef: MatDialogRef<ModDireccionComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.direccion = data;

   }

  ngOnInit(): void {
    this.api.getPaisesBasic().subscribe((paises:any)=>{
      this.paises = paises
      this.loadPais(this.direccion.pais)
    }) 
  }

  changePais(ev:any){
    let pais = ev.target.value
    this.api.getRegionesSearch(pais).subscribe((regiones: any) =>{
      this.capitales = regiones
    })
  }

  loadPais(ThePais:any){
    this.api.getRegionesSearch(ThePais).subscribe((regiones: any) =>{
      this.capitales = regiones
    })
  }

  changeCapital(ev:any){
    let region_id = ev.target.value
    this.direccion.provincia =  this.capitales.filter((cap) => cap.id == region_id)[0].nombre;
    this.api.getCuidadesSearch(region_id).subscribe((ciudades: any) =>{
      this.provincias = ciudades
    })
  }

  loadCapital(pais_id:any){
    let region_id = pais_id
    this.direccion.provincia =  this.capitales.filter((cap) => cap.id == region_id)[0].nombre;
    this.api.getCuidadesSearch(region_id).subscribe((ciudades: any) =>{
      this.provincias = ciudades
    })
  }

  selectCiudad(ev:any){
    this.direccion.ciudad = ev.target.value
  }

  selectCapital(ev:any){
    this.direccion.capital = this.capitales.filter((cap) => cap.id == ev.target.value)[0].nombre; 
  }

  agregar(){
    this.api.putUsuarioDirecciones(this.direccion.usuario,this.direccion).subscribe((user: any) => {
      this.dialogRef.close()
    });
  }

}
