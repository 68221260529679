<div>
  <!-- (ngSubmit)="agregar()" -->
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Buscar mediante Referencia</label>
    <input type="search" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
      [(ngModel)]="referencia" name="referencia" (onsearch)="closeResults()"
      (ngModelChange)="this.searhProducts.next($event)">
    <ul class="list-group mt-2 mb-2 p-2" style="height: 100px;" *ngIf="resultados.length > 0">
      <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let pro of resultados"
        [ngClass]="{'list-group-item-danger': pro.virtual == 0 }">
        <div class="ms-2 me-auto">
          <div class="fw-bold">Referencia: {{pro.referencia}}</div>
          {{pro.name}}
          <div class="fw-bold">Precio Compra: {{pro.precio_compra}}</div>
          <div class="fw-bold">Stock Virtual/Real: {{pro.virtual}}/{{pro.stock}}</div>
        </div>
        <button type="button" class="btn btn-info btn-sm" (click)="selecionar(pro)">Selecionar</button>
      </li>
    </ul>
  </div>
  <div class="row">
    <div class="mb-3 col-12">
      <label for="exampleInputEmail1" class="form-label">Nombre</label>
      <input [(ngModel)]="linea.descripcion" name="descripcion" type="text" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Referencia</label>
      <input [(ngModel)]="linea.referencia" name="referencia" type="text" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Precio Compra</label>
      <input [(ngModel)]="linea.precio_compra" name="precio_compra" type="text" class="form-control"
        id="exampleInputEmail1" aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Impuestos (%)</label>
      <input [(ngModel)]="linea.impuestos" name="impuestos" type="text" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-3">
      <label for="exampleInputEmail1" class="form-label">Unidades</label>
      <input [(ngModel)]="linea.unidades" name="unidades" type="number" class="form-control" id="exampleInputEmail1"
        aria-describedby="emailHelp">
    </div>
    <div class="mb-3 col-12">
      <label for="exampleInputEmail1" class="form-label">Descuento</label>
      <input [(ngModel)]="linea.descuentoLinea" [disabled]="linea.referencia==''" name="descuento" type="number"
        step="0.1" [max]="maxDescuento" class="form-control" id="exampleInputEmail1">
    </div>
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="button" [disabled]="linea.unidades <= 0" class="btn btn-primary btn-sm w-100"
    (click)="agregar()">Agregar</button>
</div>
