import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-configurar-transporte',
  templateUrl: './configurar-transporte.component.html',
  styleUrls: ['./configurar-transporte.component.css']
})
export class ConfigurarTransporteComponent implements OnInit {

  transporte: any = {}
  formData = new FormData()
  texto:String = "Crear Nuevo"
  actualizar:boolean = false

  constructor(private api: ApiService, public dialogRef: MatDialogRef<ConfigurarTransporteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(data!=null){
      this.transporte = data
      this.texto = "Actualizar"
      this.actualizar = true
    }
   }

  ngOnInit(): void { }

  agregar() {
    this.formData.append('nombre', this.transporte.nombre);
    this.formData.append('porcentaje', this.transporte.porcentaje);
    this.formData.append('seguro', this.transporte.seguro);
    this.formData.append('activo', this.transporte.activo);
    if(this.actualizar==false){
      this.api.postCarries(this.formData).subscribe((data: any) => {
        this.dialogRef.close()
      })
    }else{
      this.api.putCarries(this.transporte).subscribe((data: any) => {
        this.dialogRef.close()
      })
    }
    
  }

  

}
