<div class="row">
  <div class="mb-3 row">
    <label for="staticEmail" class="col-12 col-form-label">Importe en Caja</label>
    <div class="col-12">
      <input type="text" class="form-control" id="staticEmail" value="{{sesion.caja_fin}}"
        (change)="updateValor('caja_fin', $event)">
    </div>
  </div>
  <div class="mb-3 row">
    <button type="button" class="btn btn-sm btn-primary w-100" (click)="cerrar()">Confirmar</button>
  </div>
</div>
