<form (ngSubmit)="agregar()" class="row">
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Nombre</label>
    <input [(ngModel)]="transporte.nombre"  [ngModelOptions]="{standalone: true}" name="titulo" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="mb-3 col-6">
    <label for="exampleInputEmail1" class="form-label">Porcentaje Sobre Importe</label>
    <input [(ngModel)]="transporte.porcentaje"  [ngModelOptions]="{standalone: true}" name="url" type="url" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <div class="form-group mb-3">
    <input class="form-check-input m-2" type="checkbox" [(ngModel)]="transporte.seguro"  [ngModelOptions]="{standalone: true}" id="flexCheckDefault">
    <label class="form-check-label m-2" for="flexCheckDefault">
      Activar Envio con Seguro
    </label>
  </div>
  <div class="form-group mb-3">
    <input class="form-check-input m-2" type="checkbox" [(ngModel)]="transporte.activo"  [ngModelOptions]="{standalone: true}" id="flexCheckDefault">
    <label class="form-check-label m-2" for="flexCheckDefault">
      Activo
    </label>
  </div>
  <button type="submit" class="btn btn-primary btn-sm">{{texto}}</button>
</form>
