import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: any
  theError = false

  constructor(public formBuilder: FormBuilder,
    public restService: ApiService,
    public router: Router) {
      this.loginForm= this.formBuilder.group({
        username: [''],
        password: ['']
      })

     }

  ngOnInit(): void {
  }

  login(){
    this.restService.doLogin(this.loginForm.value).subscribe((data: any) => {
      window.localStorage.setItem('token', data.token);
      this.restService.getCurrentUser().subscribe((user: any) => {
        if(user.is_superuser == true || user.is_staff == true){
          this.router.navigate(['/seccion/dashboard/']);
        }
        if((user.rol == 1 || user.rol == 2) && (user.tipo == 1 || user.tipo == 2 || user.tipo == 3) && (user.is_superuser == false || user.is_staff == false)){
          this.router.navigate(['/seccion/pedidos-comerciales/']);
        }
      })
    }, (error:any)=>{
      this.theError = true
    });
  }

}
