<div class="row">
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Nombre</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="staticEmail" value="{{grupoEmpresaria.nombre}}"
      (input)="onchangeData('nombre',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Cif</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="staticEmail" value="{{grupoEmpresaria.cif}}"
      (input)="onchangeData('cif',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
    <div class="col-sm-10">
      <input type="text" class="form-control" id="staticEmail" value="{{grupoEmpresaria.email}}"
      (input)="onchangeData('email',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Comision</label>
    <div class="col-sm-10">
      <input type="number" class="form-control" id="staticEmail" step="0.01" value="{{grupoEmpresaria.comisionVenta}}"
      (input)="onchangeData('comisionVenta',$event)">
    </div>
  </div>
  <div class="mb-3 row">
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-primary" (click)="guardarDatos()">{{texto}}</button>
    </div>
  </div>
</div>

