<form (ngSubmit)="guardarDatosPedido()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Url Pago</label>
    <input [(ngModel)]="url" name="url" type="url" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
    <p class="text-danger" *ngIf="errores.urlPago!=null">{{errores.urlPago}}</p>
  </div>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Pago ID</label>
    <input [(ngModel)]="pagoID" name="pagoID" type="text" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp">
  </div>
  <button type="submit" class="btn btn-primary btn-sm w-100">Guardar</button>
</form>
