<form (ngSubmit)="agregar()">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Nombre, Email, CIF</label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
      [(ngModel)]="referencia" type="text" name="referencia" (ngModelChange)="this.searhClients.next($event)">
  </div>
  <div class="mb-3">
    <ul class="list-group mt-2 mb-2 p-2" style="height: 100px;" *ngIf="resultados.length > 0">
      <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let pro of resultados">
        <div class="ms-2 me-auto">
            <div class="fw-bold">{{pro.email}}</div>
            <div class="fw-bold">{{pro.cif}}</div>
            {{pro.first_name}}
            <div class="fw-bold">{{pro.last_name}}</div>
        </div>
        <button type="button" class="btn btn-info btn-sm" (click)="selecionar(pro)">Selecionar</button>
      </li>
    </ul>
  </div>
  <!--  [(ngModel)]="concepto.nombre" name="nombre"  -->
  <button type="submit" class="btn btn-primary btn-sm">Agregar</button>
</form>
