import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  Subject,
  debounceTime,
  distinctUntilChanged
} from 'rxjs';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  AddProductoComponent
} from '../add-producto/add-producto.component';

@Component({
  selector: 'app-add-producto-proveedor',
  templateUrl: './add-producto-proveedor.component.html',
  styleUrls: ['./add-producto-proveedor.component.css']
})
export class AddProductoProveedorComponent implements OnInit {


  linea: any = {};
  referencia: string = "";
  producto: any = {};
  resultados: any[] = [];
  searhProducts = new Subject < string > ();
  unidades: number = 0;
  pedido: any = {};
  descuento = 0.0;
  maxDescuento = 0.0;

  constructor(private api: ApiService, public dialogRef: MatDialogRef < AddProductoComponent > , @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.pedido = data;
    }
    this.searhProducts.pipe(
        debounceTime(400),
        distinctUntilChanged())
      .subscribe(value => {
        //console.log(value);
        if (value.length > 3) {
          this.api.searchProductosByProveedorID(this.referencia, this.pedido.proveedor).subscribe((data: any) => {
            this.resultados = data.results;
          });
        }
      });
  }

  ngOnInit(): void {}

  buscar() {
    this.api.searchProductos(this.referencia).subscribe((data: any) => {
      this.resultados = data.results;
    });
  }

  agregar() {
    console.log(this.linea)
    this.linea.pedido = this.pedido.id
    this.api.postGestionPedidosProveedorProductos(this.pedido.id, this.linea).subscribe((data: any) => {
      this.api.totalizarGestionPedidosProveedorProductosRecepcionarUnidad(this.pedido.id, data.id).subscribe((data: any) => {
        this.dialogRef.close();
      })
    }, (error: any) => {
      console.error(error)
    });
  }

  selecionar(producto: any) {
    this.linea.referencia = producto.referencia
    this.linea.descripcion = producto.name
    this.linea.precio_compra = producto.precio_compra
    this.resultados = []
    this.referencia = ''
  }

  closeResults(){
    if(this.referencia==''){
      this.resultados = []
    }
  }
}
