import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { PedidoAddProductoComponent } from '../pedido-add-producto/pedido-add-producto.component';

@Component({
  selector: 'app-mod-linea',
  templateUrl: './mod-linea.component.html',
  styleUrls: ['./mod-linea.component.css']
})
export class ModLineaComponent implements OnInit {

  linea: any = {};
  unidades: number = 0;
  descuento = 0.0;
  maxDescuento=0.0;
  producto: any = {}

  constructor(private api: ApiService, public dialogRef: MatDialogRef<PedidoAddProductoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.linea = data 
  }

  ngOnInit(): void {
    this.api.getProductos(this.linea.producto.id).subscribe((pro:any)=>{
      this.producto = pro
    })
  }

  actualizar() {
    this.linea.producto = this.producto.id
    this.api.putPedidoProductos(this.linea).subscribe(()=>{
      this.dialogRef.close()
    })
  }

  checkMaximun(ev:any){
    if(ev>this.maxDescuento){
      this.linea.descuentoLinea = this.maxDescuento
    }
  }

  recallDiscount(ev:any){
    this.linea.unidades = ev
    this.maxDescuento = ((this.producto.precio_compra*this.producto.margen)/2)*this.linea.unidades
  }

}
