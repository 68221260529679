<div class="row">
  <div class="mb-3 row">
    <label for="exampleInputtext1" class="col-sm-2 col-form-label">Pais</label>
    <div class="col-sm-10">
      <select class="form-select" aria-label="Default select example" (change)="onchangeData('pais',$event)">
        <option *ngFor="let data of paises" [selected]="isSelected(data)" value="{{data.code}}">
          {{data.value}}</option>
      </select>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Minimo</label>
    <div class="col-sm-10">
      <input (input)="onchangeData('min',$event)" type="number" class="form-control" id="staticEmail"
        value="{{descuento.min}}">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Maximo</label>
    <div class="col-sm-10">
      <input (input)="onchangeData('max',$event)" type="number" class="form-control" id="staticEmail"
        value="{{descuento.max}}">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Descuento ({{getPercentDiscount(descuento)}})</label>
    <div class="col-sm-10">
      <input (input)="onchangeData('descuento',$event)" type="number" class="form-control" id="staticEmail" step="0.01"
        value="{{descuento.descuento}}">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Fecha Inicio</label>
    <div class="col-sm-10">
      <input (input)="onchangeData('fecha_inicio',$event)" type="date" class="form-control" id="staticEmail"
        value="{{descuento.fecha_inicio}}">
    </div>
  </div>
  <div class="mb-3 row">
    <label for="staticEmail" class="col-sm-2 col-form-label">Fecha Fin</label>
    <div class="col-sm-10">
      <input (input)="onchangeData('fecha_fin',$event)" type="date" class="form-control" id="staticEmail"
        value="{{descuento.fecha_fin}}">
    </div>
  </div>
  <div class="mb-3 row">
    <mat-form-field class="example-full-width">
      <mat-label>Producto</mat-label>
      <input type="text" placeholder="Pick one" aria-label="Producto" matInput [formControl]="myControl"
        [matAutocomplete]="auto" (input)="searchResult($event)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectProducto($event)">
        <mat-option value="null">Ninguno</mat-option>
        <mat-option *ngFor="let producto of productos.results" [value]="producto.id">
          {{producto.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="mb-3 row">
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-primary" (click)="guardarResultados()">{{texto}}</button>
    </div>
  </div>
</div>
